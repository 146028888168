<template>
    <section class="mt-16">
      <v-row>        
        <v-col cols="12" md="4" v-if="$root.session.worker != null">
          <StyledCard title="Dias de férias" :text="$root.session.worker.vacation_days" icon="mdi-beach" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="picagens.length > 0">
          <v-card-title class="primary white--text">
            Picagens de hoje
          </v-card-title>
        </v-col>
        <v-col cols="12" md="4" v-for="picagem in picagens" :key="picagem.id">
          <StyledCard :bulleted="true" :title="picagem.work.code" :subtitle="picagem.work.name" :text="getPicagemText(picagem)" icon="mdi-pickaxe" />
        </v-col>
        <v-col cols="12">
            <WorkerCalendar />
        </v-col>
      </v-row>
    </section>
  </template>
  
  <script>
  import StyledCard from '@/components/ui/StyledCard.vue'
  import WorkerCalendar from '@/components/dashboard/internals/WorkerCalendar.vue'
  import QRCode from '@/api/QRCode.js'
  
  export default {
    name: "ColaboradorView",
    components:{
      WorkerCalendar,
      StyledCard
    },
    data() {
      return {
        picagens: []
      };
    },
    mounted(){
      this.fillBaseData()
    },
    methods:{
      getPicagemText(t){
        return 'Entrada: ' + t.hora_entrada + ':' + t.minuto_entrada + ' | Saída: ' + t.hora_saida + ':' + t.minuto_saida
      },
      fillBaseData(){
        let data = {
          worker_id: this.$root.session.worker_id,
          date: new Date()
        }

        QRCode.getPicagens(data).then((resp)  =>  {
          this.picagens = resp.data
        })
      }
    }
  };
  </script>
  