<template>
    <section class="mt-16">
        <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
            ></v-progress-linear>
        <v-breadcrumbs
            large
            :items="crumbs"
            divider="/"
            class="pl-0"
        ></v-breadcrumbs>
        <h1 class="primary--text">
        <v-icon color="primary">mdi-currency-eur</v-icon> <span>Custos</span>
      </h1>

        <v-container fluid>
            <v-card>
                <v-card-title class="primary white--text">
                    Filtros
                    <v-spacer></v-spacer>
                    <v-btn @click="expand = !expand" fab x-small>
                        <v-icon v-if="!expand">mdi-arrow-expand-all</v-icon>
                        <v-icon v-else>mdi-arrow-collapse-all</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text v-if="expand" class="mt-4">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-autocomplete
                                :items="works"
                                multiple
                                label="Obra"
                                clearable
                                chips
                                v-model="filter.work_id"
                                dense outlined
                                item-value="id"
                                :item-text="item => item.code + ' - ' + item.name"
                            />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-menu
                                v-model="start_picker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="filter.start"
                                        label="Data Inicio"
                                        clearable
                                        prepend-inner-icon="mdi-calendar"
                                        dense outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="filter.start"
                                :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                @input="start_picker= false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-menu
                                v-model="end_picker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="filter.end"
                                        clearable
                                        label="Data Fim"
                                        prepend-inner-icon="mdi-calendar"
                                        dense outlined
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="filter.end"
                                :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                @input="end_picker= false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-btn fab x-small :disabled="filter.work_id == null" @click="searchCost" class="success">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
        <v-container fluid v-if="!loading">
            <v-row v-if="fields.works != null">
                <v-col cols="12">
                    <v-card class="elevation-0">
                        <v-card-title>
                            Obra(s)
                        </v-card-title>
                        <v-card-subtitle>
                            Custo total da(s) Obra(s)
                        </v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard title="Custo Previsto" :custo="getCustoPrevisto" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard title="Custo Atual" :custo="getCustoAtual" />
                </v-col>
            </v-row>
            <v-row v-if="fields.works != null">
                <v-col cols="12">
                    <v-card class="elevation-0">
                        <v-card-title>
                            Descritivo de cada obra
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <!-- V Data Table -->
                    <v-data-table
                        :headers="headers"
                        :items="fields.works"
                        item-value="code"
                        class="elevation-1"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Obras</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.work_value="{item}">
                            {{$currency(item.work_value)}}
                        </template>
                        
                        <template v-slot:item.custo_atual="{item}">
                            {{$currency(item.custo_atual)}}
                        </template>

                        <template v-slot:item.invoiced="{item}">
                            {{item.invoiced}}%
                        </template>

                        <template v-slot:item.percentage_invoiced="{ item }">
                            {{ item.custo_atual && item.custo_atual > 0 ? (item.invoiced / item.custo_atual * 100).toFixed(2) : 0 }}%
                        </template>
                        
                        <template v-slot:item.status="{item}">
                            {{status.find(i => i.id == item.status).name}}
                        </template>

                        

                    </v-data-table>
                </v-col>
            </v-row>



            <!-- MAO DE OBRA -->
            <v-row v-if="fields.workers != null">
                <v-col cols="12">
                    <v-card class="elevation-0">
                        <v-card-title>
                            Mão de Obra
                        </v-card-title>
                        <v-card-subtitle>
                            Custo total da Mão de Obra
                        </v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard title="Custo Atual" :custo="fields.workers.total_cost" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard title="Custo por Dia" :custo="fields.workers.total_day_cost" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard icon="mdi-clock" title="Horas acumuladas" :custo="fields.workers.total_hours" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard icon="mdi-clock" title="Horas acumuladas" :custo="fields.workers.total_50 + fields.workers.total_75 + fields.workers.total_100" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard icon="mdi-sigma" title="Total 50%" :custo="fields.workers.total_50" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard icon="mdi-sigma" title="Total 75%" :custo="fields.workers.total_75" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard icon="mdi-sigma" title="Total 100%" :custo="fields.workers.total_100" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard icon="mdi-clock" title="Horas almoço" :custo="fields.workers.lunch_hour" />
                </v-col>
            </v-row>
            <v-row v-if="fields.workers != null">
                <v-col cols="12">
                    <v-card class="elevation-0">
                        <v-card-title>
                            Descritivo de cada colaborador
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <!-- V Data Table -->
                    <!-- V Data Table -->
                    <v-data-table
                        :headers="headersWorker"
                        :items="fields.workers.table"
                        item-value="code"
                        class="elevation-1"
                    >

                        <template v-slot:item.extra="{item}">
                            {{item.total_50 + item.total_75 + item.total_100}}
                        </template>

                        <template v-slot:item.without_extra="{item}">
                            {{ $decimalPlaces(item.without_extra, 2)}}
                        </template>
                    

                    </v-data-table>
                </v-col>
            </v-row>
            <!-- MAO DE OBRA -->


            <!-- MAQUINAS -->
            <v-row v-if="fields.machines != null">
                <v-col cols="12">
                    <v-card class="elevation-0">
                        <v-card-title>
                            Máquinas
                        </v-card-title>
                        <v-card-subtitle>
                            Custo total da(s) Máquina(s)
                        </v-card-subtitle>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard title="Total" :custo="fields.machines.total_cost" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard title="Custo por dia" :custo="fields.machines.total_cost_day" />
                </v-col>
                <v-col cols="12" md="3">
                    <CostCard icon="mdi-axis-arrow" title="KM/H acumulados" :custo="fields.machines.total_km" />
                </v-col>
            </v-row>
            <v-row v-if="fields.machines != null">
                <v-col cols="12">
                    <v-card class="elevation-0">
                        <v-card-title>
                            Descritivo de cada máquina
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <!-- V Data Table -->
                    <v-data-table
                        :headers="headersMachines"
                        :items="fields.machines.table"
                        item-value="code"
                        class="elevation-1"
                    >

                        <template v-slot:item.total_cost="{item}">
                            {{ $currency(item.total_cost) }}
                        </template>

                        <template v-slot:item.total_cost_day="{item}">
                            {{ $currency(item.total_cost_day) }}
                        </template>
                    

                    </v-data-table>
                </v-col>
            </v-row>
            <!-- MAQUINAS -->
        </v-container>
    </section>
</template>
<script>
import CostsAPI from '@/api/Costs.js'
import Work from '@/api/Work.js'
import CostCard from '@/components/ui/CostCard.vue'

export default{
    name: "Costs",
    components:{
        CostCard
    },
    data(){
        return{
            start_picker: false,
            end_picker: false,
            loading: true,
            filter: {
                work_id: null,
                start: null,
                end: null
            },
            fields:{
                works: null
            },
            headers: [
                { text: 'Código', value: 'code' },
                { text: 'Obra', value: 'name' },
                { text: 'Custo Previsto', value: 'work_value' },
                { text: 'Custo Atual', value: 'custo_atual' },
                { text: 'Total Faturado', value: 'invoiced' },
                { text: 'Faturado (%)', value: 'percentage_invoiced' },
                { text: 'Estado', value: 'status' }
            ],
            headersWorker: [
                { text: 'Código', value: 'worker.code' },
                { text: 'Colaborador', value: 'worker.name' },
                { text: 'Custo Previsto', value: 'worker.value' },
                { text: 'Total horas', value: 'without_extra' },
                { text: 'Extra', value: 'extra' },
                { text: 'Almoço', value: 'lunch_hour' },
                { text: '50%', value: 'total_50' },
                { text: '75%', value: 'total_75' },
                { text: '100%', value: 'total_100' },
                { text: 'Custo', value: 'total_cost' }
            ],
            headersMachines: [
                { text: 'Código', value: 'machine.code' },
                { text: 'Máquina', value: 'machine.name' },
                { text: 'Total KM/H', value: 'total_km' },
                { text: 'Custo atual', value: 'total_cost' },
                { text: 'Total custo diário', value: 'total_cost_day' }
            ],
            status: [],
            works:[],
            expand: true
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        searchCost(){
            this.loading = true
            let params = {
                work_id: this.filter.work_id,
                start: this.filter.start,
                end: this.filter.end
            }

            CostsAPI.get(params).then((resp)    =>  {
                this.fields = resp.data
                this.loading = false
            })
        },
        fillBaseData(){            
            Work.listStatus().then(({data}) => {
                this.status = Object.keys(data).map(item => {return {id: item, name: data[item]}});
            });
            
            Work.list().then(({data}) => {
                this.works = data
            });
            this.loading = false
        }
    },
    computed:{
        getCustoAtualMO(){
            let custo_atual = 0
           
            return custo_atual
        },
        getCustoAtual(){
            let custo_atual = 0
            this.fields.works.forEach(element => {
                custo_atual = custo_atual + element.custo_atual
            });
            return custo_atual
        },
        getCustoPrevisto(){
            let total_previsto = 0
            this.fields.works.forEach(element => {
                total_previsto = total_previsto + element.work_value
            });
            return total_previsto
        },
        crumbs: function() {
            return [
                {
                    text: 'Dashboard',
                    disabled: false,
                    to: '/admin',
                    exact: true,
                },
                {
                    text: 'Custos',
                    disabled: true,
                    to: window.location.href,
                    exact: true,
                },
            ];
        }
    }
}
</script>
