var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Código","name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","outlined":"","items":_vm.works,"item-value":"id","item-text":"code","readonly":"","prepend-inner-icon":"mdi-account-details","label":"Código *","error-messages":errors},model:{value:(_vm.fields.work_id),callback:function ($$v) {_vm.$set(_vm.fields, "work_id", $$v)},expression:"fields.work_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"Obra","name":"Obra","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","outlined":"","items":_vm.works,"item-value":"id","item-text":"name","readonly":"","prepend-inner-icon":"mdi-account-details","label":"Obra *","error-messages":errors},model:{value:(_vm.fields.work_id),callback:function ($$v) {_vm.$set(_vm.fields, "work_id", $$v)},expression:"fields.work_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Data","name":"Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data *","readonly":"","dense":"","outlined":"","prepend-inner-icon":"mdi-calendar","error-messages":errors},model:{value:(_vm.fields.data),callback:function ($$v) {_vm.$set(_vm.fields, "data", $$v)},expression:"fields.data"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.fields.data),callback:function ($$v) {_vm.$set(_vm.fields, "data", $$v)},expression:"fields.data"}})],1)]}}],null,true)})],1),(_vm.fields.data != null)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Artigo ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Quant. ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Preço Unit. (€) ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Total (€) ")])])]),_vm._l((_vm.fields.lines),function(artigo){return _c('tbody',{key:artigo.id},[_c('tr',[_c('td',{staticClass:"mx-4 px-4",staticStyle:{"width":"65%"}},[_c('validation-provider',{attrs:{"vid":"Artigo","name":"Artigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.filtrarArtigos,"label":"Artigo *","error-messages":errors,"dense":"","outlined":"","item-text":function (item) { return item.code + ' - ' + item.description; },"item-value":"id"},model:{value:(artigo.article_id),callback:function ($$v) {_vm.$set(artigo, "article_id", $$v)},expression:"artigo.article_id"}})]}}],null,true)})],1),_c('td',{staticStyle:{"width":"15%"}},[_c('v-text-field',{staticClass:"mt-4",attrs:{"type":'number',"dense":"","label":"Quantidade","outlined":"","placeholder":"0","persistent-hint":"","hint":'Qtd. Acumulada: ' + _vm.getQuantity(artigo)},on:{"input":function($event){return _vm.updateLine(artigo)}},model:{value:(artigo.qty),callback:function ($$v) {_vm.$set(artigo, "qty", $$v)},expression:"artigo.qty"}})],1),_c('td',{staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(_vm.$currency(_vm.getUnitPrice(artigo)))+" ")]),_c('td',{staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(_vm.$currency(artigo.total_price))+" ")])])])})]},proxy:true}],null,true)})],1):_vm._e(),(_vm.fields.data != null)?_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-divider'),_c('v-btn',{staticClass:"success mt-4",attrs:{"disabled":invalid,"fab":""},on:{"click":_vm.addLine}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e(),(_vm.fields.data != null)?_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":invalid},on:{"click":_vm.submit}},[_vm._v(" Gravar ")])],1):_vm._e()],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Auto de Medição gravado com sucesso! ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }