<template>
    <v-container fluid>
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit" class="mb-16">
                <v-row>
                    <v-col cols="12" md="5">
                        <validation-provider v-slot="{ errors }" vid="Obra" name="Obra" rules="required">
                            <v-autocomplete
                                dense outlined
                                :items="works"
                                :item-text="item => item.code + ' - ' + item.name"
                                :error-messages="errors"
                                label="Obra *"
                                item-value="id"
                                v-model="fields.work_id"
                                @change="findFronts"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="5" v-if="work_fronts.length > 0">
                        <validation-provider v-slot="{ errors }" vid="Frente de Obra" name="Frente de Obra" rules="required">
                            <v-autocomplete
                                dense outlined
                                :items="work_fronts"
                                v-model="fields.front_id"
                                :item-text="item => item.reference + ' - ' + item.description"
                                :error-messages="errors"
                                label="Frente de Obra *"
                                item-value="id"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="2">                        
                        <v-btn @click="generateQRCode" class="success" :disabled="invalid">
                            <v-icon>mdi-qrcode</v-icon>
                            Gerar QR Code
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center">
                    <v-col cols="12" justify="center" align="center">
                        <!-- Mostrar QR CODE -->
                        <div v-if="fields.qrcode_content">
                            <img :src="fields.qrcode_content" alt="QR Code"/>
                        </div>
                    </v-col>
                    <v-col justify="center" align="center" cols="12" v-if="fields.qrcode_content">
                        {{fields.full_link}}
                    </v-col>
                    <v-col cols="12" v-if="fields.qrcode_content">
                        <Search :work_id="fields.work_id" :front_id="fields.front_id" />
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>
      <SuccessSnackBar :timeout="5000" v-model="success.show" :message="success.msg" />
    </v-container>
</template>
<script>
import Work from '@/api/Work.js'
import QRCodeAPI from '@/api/QRCode.js'
import QRCode from 'qrcode'
import Search from '@/components/qrcode/registered/Search.vue'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';

export default{
    name: "CreateQRCode",
    components:{
        Search,
        SuccessSnackBar
    },
    props:{
        works: {
            typeof: Array,
            default: []
        }
    },
    data(){
        return{
            work_fronts: [],
            date: false,
            fields:{
                front_id: null,
                work_id: null,
                qrcode_content: null,
                full_link: null
            },
            success:{
                msg: null,
                show: false
            }
        }
    },
    methods:{
        generateQRCode(){
            this.fields.qrcode_content = null
            this.fields.full_link = null
            // Gerar o QRCode
            let content = process.env.VUE_APP_DOMAIN_FULL + 'admin/qr-code/register-worker-diary/' + this.fields.work_id + '/' + this.fields.front_id

            QRCode.toDataURL(content)
                .then(url => {
                    this.fields.qrcode_content = url;
                    this.fields.full_link = content
                    // QR CODE gerado, vamos registar este encarregado

                    let params = {
                        work_id: this.fields.work_id,
                        front_id: this.fields.front_id
                    }

                    QRCodeAPI.create(params).then(()   =>  {
                        this.success.msg = 'Encarregado registado com sucesso! Se já se registou uma vez, contará apenas o primeiro registo'
                        this.success.show = true
                    })
                })
                .catch(err => {
                    console.error(err);
                });
        },
        findFronts(){
            Work.find(this.fields.work_id).then(({ data }) => {
                this.work_fronts = data.work_fronts
                if(this.work_fronts.length == 1)
                    this.fields.front_id = this.work_fronts[0].id
            });
        }
    }
}
</script>