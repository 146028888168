var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[(_vm.fields.approved === "P" && _vm.method == "update" && _vm.$root.session.hasPermission(["super", "holidays.approve"]))?_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"end","justify":"end"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","align":"end"}},[_c('IconApprove',{attrs:{"big":true},on:{"on-submit":_vm.approvar}}),_c('IconReject',{attrs:{"big":true},on:{"on-submit":_vm.rejeitar}})],1)],1)],1)],1):(_vm.fields.approved != 'P')?_c('v-row',{attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"auto","align":"start","justify":"start"}},[_c('v-alert',{attrs:{"type":_vm.fields.approved == 'N' ? 'error' : 'success'}},[_vm._v(" "+_vm._s(_vm.fields.approved == 'N' ? 'Pedido rejeitado' : 'Pedido aprovado')+" "),(_vm.fields.approved == 'N')?_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.fields.justification))])]):_vm._e()])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","align":"center"}},[_c('validation-provider',{attrs:{"vid":"intervalo","name":"intervalo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-date-picker',{attrs:{"disabled":_vm.fields.approved != 'P',"error-messages":errors,"range":"","allowed-dates":_vm.allowWeekdays},model:{value:(_vm.fields.interval),callback:function ($$v) {_vm.$set(_vm.fields, "interval", $$v)},expression:"fields.interval"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"disabled":"","label":"Estado","value":_vm.getEstado(_vm.fields),"dense":"","outlined":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"disabled":"","label":"Pedido de","value":_vm.$root.session.email,"dense":"","outlined":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"disabled":"","label":"Pedido a","value":_vm.fields.created_at,"dense":"","outlined":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"disabled":"","label":"Dias úteis","value":_vm.fields.total_days,"dense":"","outlined":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"motivo","name":"Motivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.motivos,"item-text":"reason","item-value":"id","label":"Motivo","error-messages":errors,"dense":"","outlined":""},model:{value:(_vm.fields.justification),callback:function ($$v) {_vm.$set(_vm.fields, "justification", $$v)},expression:"fields.justification"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"Descrição","name":"Descrição","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"readonly":_vm.fields.approved != 'P',"dense":"","outlined":"","label":"Descrição","error-messages":errors},model:{value:(_vm.fields.description),callback:function ($$v) {_vm.$set(_vm.fields, "description", $$v)},expression:"fields.description"}})]}}],null,true)})],1)],1)],1)],1),(_vm.fields.approved === 'P')?_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[(_vm.$root.session.worker_id != null)?_c('v-col',{attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mr-5 ml-5 mb-5",attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1):_vm._e()],1):_vm._e()],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Pedido efetuado com sucesso! ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }