<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row>
            <v-col cols="12" md="auto">
                <span class="text-h6">Marcar todos como:</span>
            </v-col>
            <v-col cols="12" md="3">
                <v-autocomplete
                    dense outlined
                    :items="faultsKeys"
                    clearable
                    item-text="text"
                    item-value="id"
                    label="Tipo de falta"
                    v-model="marcacao_default_faltas"
                />
            </v-col>
            <v-col cols="12" md="auto">
                <v-btn class="success" rounded @click="marcarFaltaDefault">
                    <v-icon class="mr-2">mdi-check</v-icon>Marcar
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="itemsTotal"
            :loading="loading"
            :footer-props="footer"
            :options.sync="options"
            hide-default-footer
            :height="items.length >= 10 ? 500 : ''"
            fixed-header
            locale="pt-pt"
        >
            <template v-slot:item.name="{ item }">
                {{item.worker.code}} - {{item.name}}
            </template>

            <template v-slot:item.work_id="{ item }">
                <v-autocomplete
                    v-model="item.work_id"
                    :items="works"
                    dense outlined
                    class="mt-2"
                    :item-text="item => item.name + ' - ' + item.code"
                    item-value="id"
                    menu-props="auto"
                    :disabled="item.selected != null"
                    label="Obra"
                    clearable
                ></v-autocomplete>
            </template>

            <template v-slot:item.type="{item}">
                <v-autocomplete
                    :items="faultsKeys"
                    v-model="item.selected"
                    label="Tipo"
                    :disabled="item.work_id != null"
                    dense outlined
                    item-text="text"
                    item-value="id"
                    class="mt-2"
                    clearable
                />
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
import DiaryAPI from '@/api/Diary.js';
import Faults from '@/api/Faults.js';

export default {
    name: 'UsersNoDiary',
    props: {
        noUsersData: String,
        works: Array,
    },
    watch: {
        options: {
            immediate: false,
            handler() {
                setTimeout(() => {
                    this.searchData();
                }, 10);
            },
            deep: true,
        },
        noUsersData: {
            immediate: true,
            handler() {
                this.replacePage = true;
                setTimeout(() => {
                    this.searchData();
                }, 10);
            },
        },
    },
    mounted() {
        Faults.getKeys().then((resp) => {
            this.faultsKeys = resp.data;
        });
    },
    data() {
        return {
            marcacao_default_faltas: null,
            replacePage: false,
            loading: true,
            faultsKeys: [],
            headers: [
                {
                    text: 'Nome',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: 'Obra',
                    sortable: true,
                    value: 'work_id',
                },
                {
                    text: 'Tipo falta',
                    sortable: false,
                    value: 'type',
                },
            ],
            items: [],
            itemsTotal: 0,
            footer: {
                'items-per-page-options': [10, 15, -1],
                'items-per-page-text': 'Linhas por página',
            },
            options: {
                itemsPerPage: 10, // Mostrar inicialmente 10 registros
            },
        };
    },
    methods: {
        marcarFaltaDefault() {
            if(this.marcacao_default_faltas == null)
                return;

            this.items.forEach(element => {
                if(element.selected == null && element.work_id == null)
                    element.selected = this.marcacao_default_faltas
            });
        },
        gravar() {
            return this.items;
        },
        
        async searchData() {
            this.loading = true;
            let filter = { ...this.filter };

            if (this.replacePage) {
                this.replacePage = false;
                this.options.page = 1;
            }
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                day: this.noUsersData,
            };

            Object.assign(request, filter);

            localStorage['diaries-work-' + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });

            await DiaryAPI.checkUsersInDayFree(request).then((response) => {
                let data = response.data;

                if (data != undefined) {
                    this.items = data.data;
                    this.itemsTotal = data.total;
                } else {
                    this.items = [];
                    this.itemsTotal = 0;
                }

                this.loading = false;
            });
        },
    },
};
</script>
