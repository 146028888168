<template>
    <section>
        <v-breadcrumbs
          v-if="!asComponent"
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <!--
        <div class="d-flex align-items-center">
          <h1 class="primary--text" v-if="!asComponent">
            <v-icon color="primary">mdi-caravan</v-icon> <span>Gestão de Pedidos de Ausências</span>
          </h1>
          <v-btn icon @click="openAddHoliday" class="ml-2">
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </div>
        -->
  
        <div style="position:relative">
          
          <v-container>
            <v-speed-dial
              v-if="!asComponent"
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Opções</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$root.session.worker_id != null"
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="success"
                    large
                    to="/admin/holidays/create"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Registar</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
          
  
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16"
            locale="pt-pt"
          >
            <template v-slot:item.approved="{ item }">
              {{getEstado(item)}}
            </template>
            <template v-slot:item.actions="{ item }">
              <IconView
                class="mr-2"
                @click="viewHoliday(item)"
              />
              <IconRemove
                  v-if="canRemove(item)"
                  class="mr-2"
                  @on-submit="deleteHoliday(item)"
                />
            </template>
            
          </v-data-table>
        </div>
        
    </section>
  </template>
  <script>
  import Holidays from "@/api/Holidays.js";
  import dayjs from 'dayjs';
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  
  export default {
    components: {
      IconRemove,
      IconView,
    },
    props:{
      asComponent:{
        typeof: Boolean,
        default: false
      },
      worker_id:{
        typeof: String,
        default: null
      }
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchHolidays()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "GPD - Gestão de pedidos de férias";
    },
    data: () => ({
      headers: [
        {
          text: 'De',
          sortable: false,
          value: 'user',
          show: false
        },
        {
          text: 'Inicio',
          sortable: true,
          value: 'start_time',
        },
        {
          text: 'Fim',
          sortable: true,
          value: 'end_date',
        },
        {
          text: 'Total dias úteis',
          sortable: true,
          value: 'total_days'
        },
        {
          text: 'Motivo',
          sortable: true,
          value: 'reason',
        },
        {
          text: 'Descrição',
          sortable: true,
          value: 'description',
        },
        {
          text: 'Estado',
          sortable: true,
          value: 'approved',
        },
        { 
          text: 'Opções', 
          value: 'actions', 
          sortable: false 
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        
      },
    }),
    mounted(){
  
      /*
      if(this.$root.session.worker_id == null || !this.$root.session.hasPermission(["super", "holidays.approve"])){ 
        this.$router.push('/no-permission');
      }
        */

      if(!this.$root.session.hasPermission(["super", "holidays.approve"])){
          //this.$router.push('/no-permission');
          if(this.$root.session.worker_id == null){
            this.$router.push('/no-permission');
          }
      }
  
      this.fillBaseData();
    },
    methods:{
      canRemove(item){
        //($root.session.hasPermission(['super', 'holidays.delete']) || item.user_id == $root.session.id) && item.approved === 'P'
        if(this.$root.session.hasPermission(['super', 'holidays.delete']))
          return true;

        if(item.user_id == this.$root.session.id && item.approved === 'P')
          return true;

        return false;
      },
      getEstado(item){
        if(item.approved == 'P')
          return 'Pendente';
        if(item.approved == 'N')
          return 'Rejeitado';
        
        return 'Aprovado';
      },
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
  
        if(localStorage["holidays-request-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["holidays-request-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchHolidays(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};

        let user_id = this.$root.session.id
        if(this.$root.session.hasPermission(['super', 'holidays.approve']))
          user_id = null

        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          user_id: user_id,
          worker_id: this.worker_id
        };
      
        Object.assign(request, filter);
  
        localStorage["holidays-request-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Holidays.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
          this.loading = false;
  
        });
      },

      openAddHoliday(){
        this.$router.push('/admin/holidays/create');
      },

      resetHolidays()
      {
        this.filter = {
        };
  
        this.searchHolidays();
      },

      viewHoliday(item){

        console.log(item.id);

        let url = '/admin/holidays/' + item.id;

        console.log(url);

        this.$router.push(url);
      },

      deleteHoliday(item){
        Holidays.delete(item.id)
          .then(() => {
            this.searchHolidays();
          });
      },
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de pedidos de ausências',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  