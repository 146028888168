<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-caravan</v-icon> <span>Pedidos de ausências</span>
        </h1>
  
        <div class="mt-16">
          <Holiday method="create"></Holiday>
        </div>
    </section>
  </template>
  <script>
  import Holiday from '@/components/holidays/Holiday'
  
  export default {
    components: {
        Holiday
    },
    beforeCreate(){
      document.title = "GPD - Pedidos de ausências";
    },
    data: () => ({
      
    }),
    mounted(){
      let sair = false
      if(this.$root.session.worker_id == null) {
        sair = true
      }
      if(this.$root.session.hasPermission(['super', 'holidays.approve']))
      {
        sair = false
      }
      if(sair == true)
      {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Pedidos de ausências',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  