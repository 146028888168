<template>
  <div :key="generalKey">
    <v-tabs v-model="tab" fixed-tabs dark background-color="primary" class="elevation-2">
      <v-tab>Detalhe</v-tab>
      <v-tab>Imagens</v-tab>
      <v-tab v-if="method == 'update' && $root.session.hasRole(['super', 'dto'])">Medições</v-tab>
      <v-tab v-if="method == 'update'">Histórico</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">

      <v-container class="mb-n12">

        <v-alert v-if="locked_edition" prominent
          type="error">
          <v-row align="center">
            <v-col class="grow">
              Esta diária está aprovada. <span v-if='$root.session.hasPermission(["super", "disapprove.diaries"])'>Se pretende editar poderá faze-lo ao clicar no botão. A diária continuará
              aprovada após edição</span>
            </v-col>
            <v-col class="shrink" v-if='$root.session.hasPermission(["super", "disapprove.diaries"])'>
              <v-btn @click="locked_edition = false" color="warning">
                <v-icon small class="mr-2">mdi-lock-open-alert</v-icon>
                Desbloquear
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <div v-if="diaryCreationPossible">
          <v-btn v-if="tab == 0 && $route.name == 'create'" class="mr-2" style="float:right;" dark small color="primary"
            @click="replicarPD">
            <v-icon small class="mr-2">mdi-plus-circle-multiple-outline</v-icon>
            <div v-if="!replicar">Copiar anterior</div>
            <div v-else>Remover cópia</div>
          </v-btn>
        </div>
        <div v-if="$route.name == 'create' && readyToUseTemplate()">
          <v-btn v-if="diaryCreationPossible" :disabled="blockTemplate" class="mr-2" dark small color="warning"
            style="float:right;" @click="defineTemplate">
            <v-icon small class="mr-2">mdi-plus-circle-multiple-outline</v-icon>
            Importar template
          </v-btn>
        </div>
      </v-container>
      <v-tab-item class="mt-12" :key="1">
        <v-container>
          <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit" class="mb-16" :readonly="loading || locked_edition">
              <v-progress-linear v-if="loading" indeterminate class="global-loader"></v-progress-linear>
              <v-row>
                <v-col cols="12" md="5">
                  <validation-provider v-slot="{ errors }" vid="work" name="Obra" rules="required">
                    <Select
                      v-if="$route.name == 'create' || $route.name == 'Offline Diary'"
                      v-model="fields.work_id"
                      :items="works"
                      dense outlined
                      :item-text="item => newDiary ? ('Nome: ' + item.name + ' - Código: ' + item.code) : item.name"
                      item-value="id"
                      menu-props="auto"
                      label="Obra *"
                      prepend-inner-icon="mdi-hard-hat"
                      @input="setDiaryCode"
                      @change="changedWork"
                      :error-messages="errors">
                    </Select>
                    <v-text-field
                      v-else
                      prepend-inner-icon="mdi-barcode"
                      :value="fields.work_object.code + ':' + fields.work_object.name"
                      disabled dense outlined
                      label="Obra *"
                      clearable
                      :error-messages="errors">
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2" v-if="enableWorkFronts">
                  <validation-provider v-slot="{ errors }" vid="Frente de Obra" name="Frente de Obra" rules="required">
                    <v-autocomplete
                      :items="work_fronts"
                      :error-messages="errors"
                      v-model="fields.work_front_id"
                      dense outlined
                      :disabled="$route.name == 'create' ? false : true"
                      :item-text="item => item.reference == null ? item.description : item.reference + ' - ' + item.description"
                      item-value="id"
                      label="Frente de Obra *"
                      @change="checkDiaryCreation"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2" v-if="false">
                  <v-checkbox
                    :disabled="blockReplicar"
                    v-model="replicar"
                    label="Copiar última"
                    @change="replicarPD"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider v-slot="{ errors }" vid="code" name="Código" rules="required">
                    <v-text-field
                      v-model="fields.code"
                      dense outlined
                      prepend-inner-icon="mdi-barcode"
                      label="Código *"
                      :disabled="$route.name == 'create' ? false : true"
                      :error-messages="errors">
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2">
                  <v-menu v-model="date" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider v-slot="{ errors }" vid="date_buy" name="Data da Parte Diária" rules="required">
                        <v-text-field
                          label="Data"
                          dense outlined
                          v-model="fields.date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="fields.date = null"
                          :error-messages="errors">
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="fields.date"
                      :allowed-dates="(date) => date <= new Date(new Date() + 1).toISOString().substr(0, 10)"
                      locale="pt-pt"
                      @input="date = false;"
                      @change="changedData">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row v-if="!diaryCreationPossible">
                <v-alert border="left" prominent outlined colored-border type="warning" elevation="2"
                  style="width: 100%">
                  Selecione a <strong>Obra</strong> e a <strong>Data</strong> para avançar!
                </v-alert>
              </v-row>

              <!--  *************  -->
              <!--  *************  -->
              <!--  COLABORADORES  -->
              <!--  *************  -->
              <!--  *************  -->

              <v-row v-show="diaryCreationPossible">
                <v-col cols="12" class="mt-n4">
                  <v-row>
                    <v-col cols="12" md="auto">
                      <h2 class="text-h6 font-weight-bold">
                        Mão de Obra
                      </h2>
                    </v-col>

                    
                    
                    <!--<v-btn 
                      v-if="qrcodeWorkerData.length > 0"
                      @click="importQRCodeMO"
                      class="success mt-2"
                      small
                    >
                      <v-icon class="mr-2">mdi-import</v-icon>Importar Mão Obra
                    </v-btn>-->


                    <v-col cols="12" md="auto" v-if="method=='update'">
                      <BtnApproveDiary v-if="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == false" @on-submit="approveTopic('mao_obra')"/>
                      <div v-else>
                        <BtnDisapprove v-if='$root.session.hasPermission(["super", "disapprove.diaries"]) && locked_edition == false' topic="mao_obra" @on-submit="disapproveTopic"/>
                      </div>
                    </v-col>
                  </v-row>
                    
                  <v-divider></v-divider>
                  <span class="font-weight-bold text-caption">
                    <v-icon color="grey">mdi-circle-medium</v-icon>
                    Externa
                  </span>
                  <v-simple-table dense class="d-none d-lg-block mt-4">
                    <template v-slot:default>
                      <thead class="primary">
                        <tr>
                          <th class="text-center white--text rounded-pill rounded-r-0">
                            Nome
                          </th>
                          <th class="text-center white--text">
                            Texto livre
                          </th>
                          <th class="text-center white--text">
                            Horário
                          </th>
                          <th class="text-center white--text">
                            Almoço
                          </th>
                          <th class="text-center white--text">
                            Trabalho Noturno
                          </th>
                          <th class="text-center white--text">
                            Pernoita
                          </th>
                          <th class="text-center white--text">
                            Peq. Almoço
                          </th>
                          <th class="text-center white--text rounded-pill rounded-l-0">
                            Opções
                          </th>
                          <th class="orange darken-4 text-center white--text" v-if='$root.session.hasRole(["super", "dto"])'>
                            Custo (€)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(worker, index) in fields.workers" v-bind:key="index"
                          :class="worker.id_company != null ? 'grey lighten-2' : ''">
                          <td class="text-center">
                            <validation-provider v-slot="{ errors }" vid="laborer" name="Colaborador" rules="isValidWorker">
                              <v-autocomplete
                                  v-model="worker.id"
                                  label="Nome *"
                                  :filter="filterAutocomplete"
                                  :items="workers"
                                  :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                  :item-text="item => item.name"
                                  item-value="id" hide-selected
                                  :error-messages="errors"
                                  clearable
                                  @change="changedWorker(worker, index)"
                                >

                                <template v-slot:prepend>
                                  <TooltipErrors v-if="!validWorkHand(worker).result" color="error"
                                    :messages="validWorkHand(worker).errors" bottom>
                                    <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                  </TooltipErrors>
                                  <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                </template>

                              </v-autocomplete>
                            </validation-provider>
                          </td>
                          <td class="text-center">
                            <v-text-field
                              v-model="worker.reason"
                              label="Texto livre"
                              :key="keyReason"
                              @click="openEditorModal(worker, fields, 'mao_obra')"></v-text-field>
                          </td>
                          <td class="text-center">
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="hour" name="Hora"
                                      rules="required|between_hour">
                                      <v-text-field
                                        v-model="worker.started_hour"
                                        type="number"
                                        label="Hora Entrada *"
                                        :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                        :error-messages="errors"
                                        @change="changedWorker(worker, index)"
                                      ></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="minute" name="Minuto"
                                      rules="required|between_minute">
                                      <v-text-field
                                        v-model="worker.started_minute"
                                        :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                        label="Minuto Entrada *"
                                        type="number"
                                        :error-messages="errors"
                                        @change="changedWorker(worker, index)"></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="hour" name="Hora"
                                      rules="required|between_hour">
                                      <v-text-field
                                        v-model="worker.finished_hour"
                                        :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                        label="Hora Saída *"
                                        type="number"
                                        :error-messages="errors"
                                        @change="changedWorker(worker, index)"></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <validation-provider v-slot="{ errors }" vid="minute" name="Minuto"
                                      rules="required|between_minute">
                                      <v-text-field
                                        v-model="worker.finished_minute"
                                        :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                        label="Minuto Saída *"
                                        type="number"
                                        :error-messages="errors"
                                        @change="changedWorker(worker, index)"></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </td>
                          <td class="text-center">
                            <v-checkbox  :disabled="locked_edition || fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true" v-model="worker.lunch" />
                          </td>
                          <td class="text-center">
                            <v-checkbox :disabled="locked_edition || fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true" v-model="worker.night_work" />
                          </td>
                          <td class="text-center">
                            <v-checkbox :disabled="locked_edition || fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true" v-model="worker.slept_night"
                              @change="pernoitaChanged(worker)" />
                          </td>
                          <td class="text-center">
                            <v-checkbox :disabled="locked_edition || fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true" v-model="worker.breakfast" />
                          </td>
                          <td class="text-center">
                            <div v-if="!locked_edition">
                              <v-row v-if="fields.approvals == undefined ? true : fields.approvals.topics.mao_obra != true">
                                <v-col cols="12" md="6">
                                  <v-btn class="mt-2" dark small color="secondary" @click="duplicateWorker(worker)">
                                    <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                  </v-btn>

                                  <v-btn class="mt-2" dark small color="error" @click="removeWorker(worker.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </div>
                            <p></p>
                          </td>
                          <td v-if="$root.session.hasRole(['super', 'dto'])">
                            {{worker.cost}}€
                          </td>
                        </tr>
                      </tbody>

                    </template>
                  </v-simple-table>
                  <v-data-iterator :items="fields.workers" item-key="id" :items-per-page="-1" hide-default-footer
                    class="d-block d-lg-none">
                    <template v-slot:default="{ items }">
                      <v-row>
                        <v-col v-for="(worker, index) in items" :key="worker.id" cols="12" sm="6" md="4" lg="3">
                          <v-card>
                            <div class="text-center">
                              <v-chip class="mt-4 primary" v-if="worker.id_company != null">
                                <span>
                                  <v-icon color="white">mdi-location-exit</v-icon> Externo
                                </span>
                              </v-chip>
                              <v-chip class="mt-4 primary" v-else>
                                <span>
                                  <v-icon color="white">mdi-domain</v-icon> Interno
                                </span>
                              </v-chip>
                            </div>
                            <v-list dense>

                              <v-list-item>
                                <v-list-item-content>Nome:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="laborer" name="Colaborador"
                                    rules="isValidWorker">
                                    <v-autocomplete
                                      v-model="worker.id"
                                      label="Nome *"
                                      :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                      :filter="filterAutocomplete"
                                      :items="workers" :item-text="item => item.name" item-value="id" hide-selected
                                      :error-messages="errors" clearable @change="changedWorker(worker, index)">

                                      <template v-slot:prepend>
                                        <TooltipErrors v-if="!validWorkHand(worker).result" color="error"
                                          :messages="validWorkHand(worker).errors" bottom>
                                          <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                        </TooltipErrors>
                                        <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                      </template>

                                    </v-autocomplete>
                                  </validation-provider>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Horário:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-row>
                                    <v-col cols="12" lg="6">
                                      <v-row>
                                        <v-col cols="12" lg="6">
                                          <validation-provider v-slot="{ errors }" vid="hour" name="Hora"
                                            rules="required">
                                            <v-text-field
                                              v-model="worker.started_hour"
                                              :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                              label="Hora Entrada *"
                                              :error-messages="errors"
                                              @change="changedWorker(worker, index)"></v-text-field>
                                          </validation-provider>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                          <validation-provider v-slot="{ errors }" vid="minute" name="Minuto"
                                            rules="required">
                                            <v-text-field
                                              v-model="worker.started_minute"
                                              :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                              label="Minuto Entrada *"
                                              :error-messages="errors"
                                              @change="changedWorker(worker, index)"></v-text-field>
                                          </validation-provider>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                      <v-row>
                                        <v-col cols="12" lg="6">
                                          <validation-provider v-slot="{ errors }" vid="hour" name="Hora"
                                            rules="required">
                                            <v-text-field
                                              v-model="worker.finished_hour"
                                              :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                              label="Hora Saída *"
                                              :error-messages="errors"
                                              @change="changedWorker(worker, index)"></v-text-field>
                                          </validation-provider>
                                        </v-col>
                                        <v-col cols="12" lg="6">
                                          <validation-provider v-slot="{ errors }" vid="minute" name="Minuto"
                                            rules="required">
                                            <v-text-field
                                              v-model="worker.finished_minute"
                                              :disabled="fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true"
                                              label="Minuto Saída *"
                                              :error-messages="errors"
                                              @change="changedWorker(worker, index)"></v-text-field>
                                          </validation-provider>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Almoço:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-checkbox :disabled="locked_edition || fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true" v-model="worker.lunch" />
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Trabalho Noturno:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-checkbox :disabled="locked_edition || fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true" v-model="worker.night_work" />
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Pernoita:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-checkbox :disabled="locked_edition || fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true" v-model="worker.slept_night"
                                    @change="pernoitaChanged(worker)" />
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Peq. Almoço:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-checkbox :disabled="locked_edition || fields.approvals == undefined ? false : fields.approvals.topics.mao_obra == true" v-model="worker.breakfast" />
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="$root.session.hasRole(['super', 'dto'])">
                                <v-list-item-content>Custo (€):</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-text-field :value="worker.cost" />
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="!locked_edition">
                                <v-list-item-content>Opções:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-row>
                                    <v-col cols="12" md="6">
                                      <v-btn dark small block color="secondary" @click="duplicateWorker(worker)">
                                        <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-btn dark small block color="error" @click="removeWorker(worker.id)">
                                        <v-icon small>mdi-delete</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                  <v-row v-if="!locked_edition" id="machines" class="mt-7">
                    <v-col cols="12">
                      <div style="position: absolute; right: 0;">
                        <span class="font-weight-bold">Total: </span>{{calculateTotalWorkersCost}}
                      </div>
                      <div style="position: relative; width: 100%; display: flex; justify-content: center;">
                        <!-- Botão + centralizado no quadrado vermelho -->
                        <v-btn v-if="fields.approvals == undefined ? true : fields.approvals.topics.mao_obra == false" fab dark small color="primary" @click="addWorker">
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>

                      </div>

                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!--  *************  -->
              <!--  *************  -->
              <!--    MÁQUINAS     -->
              <!--  *************  -->
              <!--  *************  -->

              <v-row v-show="diaryCreationPossible">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="auto">
                      <h2 class="text-h6 font-weight-bold">
                        Máquinas
                      </h2>
                    </v-col>
                    <v-col cols="12" md="auto" v-if="method=='update'">
                      <BtnApproveDiary v-if="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == false" @on-submit="approveTopic('maquinas')"/>
                      <div v-else>
                        <BtnDisapprove v-if='$root.session.hasPermission(["super", "disapprove.diaries"]) && locked_edition == false' topic="maquinas" @on-submit="disapproveTopic"/>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <span class="font-weight-bold text-caption">
                    <v-icon color="grey">mdi-circle-medium</v-icon>
                    Externa
                  </span>
                  <v-simple-table dense class="d-none d-lg-block mt-4">
                    <template v-slot:default>
                      <thead class="primary">
                        <tr>
                          <th class="text-center white--text rounded-pill rounded-r-0" style="width: 35%;">
                            Nome
                          </th>
                          <th class="text-center white--text">
                            Código
                          </th>
                          <th class="text-center white--text">
                            Diesel
                          </th>
                          <th class="text-center white--text">
                            Ad Blue
                          </th>
                          <th class="text-center white--text">
                            Horas/KM Inicio
                          </th>
                          <th class="text-center white--text">
                            Horas/KM Fim
                          </th>
                          <th class="text-center white--text">
                            Parado à Ordem de
                          </th>
                          <th class="text-center white--text rounded-pill rounded-l-0">
                            Opções
                          </th>
                          <th class="orange darken-4 text-center white--text" v-if='$root.session.hasRole(["super", "dto"])'>
                            Custo (€)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(machine, index) in fields.machines" v-bind:key="machine.id"
                          :class="machine.external ? 'grey lighten-2' : ''">
                          <td class="text-center" :key="reloadCombo">
                            <validation-provider v-slot="{ errors }" vid="machine" name="Máquina" rules="required">
                              <v-autocomplete
                                v-model="machine.id"
                                :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                label="Máquina *" 
                                :filter="filterAutocomplete"
                                :items="machines"
                                :item-text="item => item.code + ' - ' + (item.abbreviation == null ? item.name : item.abbreviation)"
                                item-value="id" 
                                hide-selected 
                                :error-messages="errors"                                
                                @change="machineChanged(machine, index);"
                                >

                                <template v-slot:prepend>

                                  <Tooltip v-if="leftAverage(machine)" class="my-4 mr-4" color="info darken-2"
                                    style="z-index: 9999;"
                                    text="Os valores de km/horas ultrapassam a média geral da máquina" top>
                                    <v-icon color="info darken-2">mdi-shield-car</v-icon>
                                  </Tooltip>
                                  <Tooltip v-if="machine.warning" class="my-4 mr-4" color="orange darken-2"
                                    style="z-index: 9999;"
                                    text="Esta máquina está em outra diária no mesmo dia!" top>
                                    <span >
                                      <v-icon color="orange darken-2">mdi-robot-confused</v-icon>
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    v-if="machine.status != 'Á ordem de obra ou com parte diária' && machine.machine_diary_break != null"
                                    class="my-4 mr-4" color="orange darken-2" :text="machine.machine_diary_break" top>
                                    <v-icon color="orange darken-2">mdi-sync-alert</v-icon>
                                  </Tooltip>
                                  <TooltipErrors v-if="!validMachine(machine).result" color="error"
                                    :messages="validMachine(machine).errors" bottom>
                                    <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                  </TooltipErrors>
                                  <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                </template>
                              </v-autocomplete>
                            </validation-provider>
                          </td>
                          <td class="text-center">
                            {{ machine.code }}
                          </td>
                          <td class="text-center">
                            <validation-provider v-slot="{ errors }" vid="diesel" name="Diesel" rules="required"
                              v-if="machine.uses_diesel">
                              <v-text-field
                                v-model="machine.diesel"
                                :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                label="Diesel *"
                                :error-messages="errors"
                                @keypress="onlyNumbers($event)"
                                @input="handleCommaToDot(machine, 'diesel')"
                                @change="machineChanged(machine, index, false);">
                              </v-text-field>
                            </validation-provider>
                            <p v-else>-</p>
                          </td>
                          <td class="text-center">
                            <validation-provider v-slot="{ errors }" vid="ad_blue" name="Ad blue" rules="required"
                              v-if="machine.uses_ad_blue">
                              <v-text-field
                                v-model="machine.ad_blue"
                                :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                label="Ad Blue *" 
                                @change="machineChanged(machine, index, false);"
                                @input="handleCommaToDot(machine, 'ad_blue')"
                                :error-messages="errors" @keypress="onlyNumbers($event)"></v-text-field>
                            </validation-provider>
                            <p v-else>-</p>
                          </td>
                          <td class="text-center">
                            <validation-provider v-slot="{ errors }" vid="horas_km_ini" name="Horas/KM"
                              rules="required" v-if="contaHorasAtivo(machine)">
                              <v-text-field
                                v-model="machine.started_hours"
                                :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                label="Horas/KM Início*"
                                :error-messages="errors" 
                                @keypress="onlyNumbers($event)"
                                @input="handleCommaToDot(machine, 'started_hours')"
                                @change="machineChanged(machine, index, false)"></v-text-field>
                            </validation-provider>
                            <span v-else>-</span>
                          </td>
                          <td class="text-center">
                            <validation-provider v-slot="{ errors }" vid="horas_km_end" name="Horas/KM"
                              rules="required"  v-if="contaHorasAtivo(machine)">
                              <v-text-field
                                v-model="machine.finished_hours"
                                :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                label="Horas/KM Fim *"
                                @input="handleCommaToDot(machine, 'finished_hours')"
                                @change="changedMachineEndHour(machine);machineChanged(machine, index, false);" 
                                :error-messages="errors" 
                                @keypress="onlyNumbers($event)">
                              </v-text-field>
                            </validation-provider>
                            <span v-else>-</span>
                          </td>
                          <td class="text-center">
                            <v-text-field
                              v-model="machine.stopped_reason"
                              :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                              label="Razão"></v-text-field>
                          </td>
                          <td class="text-center">
                            <div v-if="!locked_edition">
                              <v-row v-if="fields.approvals == undefined ? true : fields.approvals.topics.maquinas == false">
                                <v-col cols="12" md="6">
                                  <Tooltip v-if="machine.status != 'Avaria total'" class="my-4 mr-4"
                                    color="orange darken-2" text="Anunciar avaria" top>
                                    <v-btn class="mt-2" dark small color="orange darken-2"
                                      @click="machineBreakdown(machine)">
                                      <v-icon small>mdi-sync-alert</v-icon>
                                    </v-btn>
                                  </Tooltip>
                                  <v-btn class="mt-2" dark small color="secondary" @click="duplicateMachine(machine)">
                                    <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                  </v-btn>
                                  <v-btn class="mt-2" dark small color="error" @click="removeMachine(machine.id)">
                                    <v-icon small>mdi-delete</v-icon>
                                  </v-btn>
                                  <p></p>
                                </v-col>
                              </v-row>
                            </div>
                          </td>
                          <td v-if="$root.session.hasRole(['super', 'dto'])">
                            {{machine.cost}}€
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-data-iterator :items="fields.machines" item-key="id" :items-per-page="-1" hide-default-footer
                    class="d-block d-lg-none">
                    <template v-slot:default="{ items }">
                      <v-row>
                        <v-col v-for="(machine, index) in items" :key="machine.id" cols="12" sm="6" md="4" lg="3">
                          <v-card>
                            <div class="text-center">
                              <v-chip class="mt-4 primary" v-if="machine.external">
                                <span>
                                  <v-icon color="white">mdi-location-exit</v-icon> Externo
                                </span>
                              </v-chip>
                              <v-chip class="mt-4 primary" v-else>
                                <span>
                                  <v-icon color="white">mdi-domain</v-icon> Interno
                                </span>
                              </v-chip>
                            </div>
                            <v-list dense>
                              <v-list-item>
                                <v-list-item-content>Nome:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="machine" name="Máquina"
                                    rules="required">
                                    <v-autocomplete
                                      v-model="machine.id"
                                      :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                      label="Máquina *" 
                                      :filter="filterAutocomplete"
                                      :items="machines"
                                      :item-text="item => item.code + ' - ' + (item.abbreviation == null ? item.name : item.abbreviation)"
                                      item-value="id" 
                                      hide-selected 
                                      :error-messages="errors"                                
                                      @change="machineChanged(machine, index);"
                                      >
                                    </v-autocomplete>
                                  </validation-provider>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Código:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  {{ machine.code }}
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Diesel:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="diesel" name="Diesel" rules="required"
                                    v-if="machine.uses_diesel">
                                    <v-text-field
                                      v-model="machine.diesel"
                                      :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                      label="Diesel *"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                  <p v-else>-</p>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Ad Blue:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="ad_blue" name="Ad Blue" rules="required"
                                    v-if="machine.uses_ad_blue">
                                    <v-text-field
                                      v-model="machine.ad_blue"
                                      @input="handleCommaToDot(machine, 'ad_blue')"
                                      :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                      label="Ad Blue *"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                  <p v-else>-</p>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Horas/KM Início:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="diesel" name="Horas/KM"
                                    rules="required" v-if="contaHorasAtivo(machine)">
                                    <v-text-field
                                      v-model="machine.started_hours"
                                      @input="handleCommaToDot(machine, 'started_hours')"
                                      :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                      label="Horas/KM Início*"
                                      :error-messages="errors" @keypress="onlyNumbers($event)" @change="machineChanged(machine, index)"></v-text-field>
                                  </validation-provider>
                                  <span v-else>-</span>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Horas/KM Fim:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="diesel" name="Horas/KM"
                                  rules="required" v-if="contaHorasAtivo(machine)">
                                    <v-text-field
                                      v-model="machine.finished_hours"
                                      @input="handleCommaToDot(machine, 'finished_hours')"
                                      :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                      label="Horas/KM Fim *"
                                      :error-messages="errors" @keypress="onlyNumbers($event)" @change="machineChanged(machine, index)"></v-text-field>

                                  </validation-provider>
                                  <span v-else>-</span>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Parado à Ordem de:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-text-field  :disabled="fields.approvals == undefined ? false : fields.approvals.topics.maquinas == true"
                                    v-model="machine.stopped_reason"
                                    label="Razão"></v-text-field>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="$root.session.hasRole(['super', 'dto'])">
                                <v-list-item-content>Custo (€):</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  {{machine.cost}}€
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="!locked_edition">
                                <v-list-item-content>Opções:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-row v-if="fields.approvals == undefined ? true : fields.approvals.topics.maquinas == false">
                                    <v-col cols="12" md="6"
                                      v-if="machine.status == 'Á ordem de obra ou com parte diária'">
                                      <Tooltip class="my-4 mr-4" color="orange darken-2" block text="Anunciar avaria"
                                        top>
                                        <v-btn class="mt-2" dark small block color="orange darken-2"
                                          @click="machineBreakdown(machine)">
                                          <v-icon small>mdi-sync-alert</v-icon>
                                        </v-btn>
                                      </Tooltip>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-btn dark small block color="secondary" @click="duplicateMachine(machine)">
                                        <v-icon small>mdi-plus-circle-multiple-outline</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-btn dark small block color="error" @click="removeMachine(machine.id)">
                                        <v-icon small>mdi-delete</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                  <v-row v-if="!locked_edition" id="machines" class="mt-7">
                    <v-col cols="12">
                      <div style="position: absolute; right: 0;">
                        <span class="font-weight-bold">Total: </span>{{calculateTotalMachinesCost}}
                      </div>

                      <div style="position: relative; width: 100%; display: flex; justify-content: center;">
                        <!-- Botão + centralizado no quadrado vermelho -->
                        <v-btn fab v-if="fields.approvals == undefined ? true : fields.approvals.topics.maquinas == false" dark small color="primary" @click="addMachine">
                          <v-icon small>mdi-plus</v-icon>
                        </v-btn>

                      </div>

                     
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!--  *************  -->
              <!--  *************  -->
              <!--       UOs       -->
              <!--  *************  -->
              <!--  *************  -->

              <v-row v-show="diaryCreationPossible">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="auto">                      
                      <h2 class="text-h6 font-weight-bold">
                        Unidade de Obra
                      </h2>
                    </v-col>
                    <v-col cols="12" md="auto" v-if="method=='update'">
                      <BtnApproveDiary  v-if="fields.approvals == undefined ? false : fields.approvals.topics.unidades_obra == false" @on-submit="approveTopic('unidades_obra')"/>
                      <div v-else>
                        <BtnDisapprove v-if='$root.session.hasPermission(["super", "disapprove.diaries"]) && locked_edition == false'  topic="unidades_obra" @on-submit="disapproveTopic"/>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <div class="d-flex flex-row align-center" style="width: 100%;">
                    <span class="font-weight-bold text-caption">
                      <v-icon color="grey">mdi-circle-medium</v-icon>
                      Subempreitada
                    </span>
                    <v-spacer></v-spacer>
                    <label><small>Dividir em todos, horas de:</small></label>
                    <div class="text-caption d-flex flex-row align-center">
                      <v-checkbox v-model="fields.divideAllWorkHand"
                        :disabled="fields.approvals == undefined ? false : fields.approvals.topics.unidades_obra == true"
                        @change="changeCheckDivider('maodeobra')"></v-checkbox>Mão de
                      Obra
                      <v-checkbox v-model="fields.divideAllMachines"
                        :disabled="fields.approvals == undefined ? false : fields.approvals.topics.unidades_obra == true"
                        @change="changeCheckDivider('maquinas')"></v-checkbox>Máquinas
                    </div>
                  </div>
                  <v-simple-table dense class="d-none d-lg-block">
                    <template v-slot:default>
                      <thead class="primary">
                        <tr>
                          <th class="text-center white--text rounded-pill rounded-r-0" style="width:40%;">
                            Nome
                          </th>
                          <th class="text-center white--text">
                            Código
                          </th>
                          <th class="text-center white--text">
                            Texto livre
                          </th>
                          <th class="text-center white--text">
                            Medição
                          </th>
                          <th class="text-center white--text">
                            Unidade
                          </th>
                          <th class="text-center white--text rounded-pill rounded-l-0">
                            Opções
                          </th>
                          <th class="orange darken-4 text-center white--text rounded-pill"
                            v-if='method == "update" && $root.session.hasPermission(["super", "diary.access_special_view"])'>
                            Medição / Orçamentado
                          </th>
                        </tr>
                      </thead>
                      <tbody :key="reloadUnidadeObra">
                        <tr v-for="work_unit in fields.work_units" v-bind:key="work_unit.id"
                          :class="work_unit.subcontractor_id != null ? 'grey lighten-2' : ''">

                          <td class="text-center">
                            <validation-provider v-slot="{ errors }" vid="action_name" name="Unidade Obra"
                              rules="required">
                              <v-combobox
                                v-model="work_unit.name"
                                :disabled="fields.approvals == undefined ? false : fields.approvals.topics.unidades_obra == true"
                                label="Unidade Obra *" :filter="filterAutocomplete"
                                :items="units"
                                @input="work_unit.code = $event.code; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                                item-value="id" item-text="name" hide-selected :error-messages="errors">

                                <template v-slot:prepend>
                                  <TooltipErrors v-if="!validWorkUnit(work_unit).result" color="error"
                                    :messages="validWorkUnit(work_unit).errors" bottom>
                                    <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                  </TooltipErrors>
                                  <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                </template>

                              </v-combobox>
                            </validation-provider>
                          </td>
                          <td class="text-center">
                            {{ work_unit.code }}
                          </td>
                          <td class="text-center">
                            <v-text-field v-model="work_unit.reason" label="Texto livre" class="align-end"
                              @click="openEditorModal(work_unit, fields, 'unidades_obra')"></v-text-field>
                          </td>
                          <td class="text-center">
                            <validation-provider v-slot="{ errors }" vid="measure" name="Medição *" rules="required">
                              <v-text-field
                              v-model="work_unit.measure"
                              label="Medição *"
                              @input="handleCommaToDot(work_unit, 'measure')"
                              :disabled="fields.approvals == undefined ? false : fields.approvals.topics.unidades_obra == true"
                              :error-messages="errors"
                              @keypress="onlyNumbers($event)"
                            >
                                <template v-slot:prepend>
                                  <TooltipErrors v-if="checkBudgeted(work_unit)" color="warning"
                                    :messages="['Valor ultrapassa 20% ou mais do orçamentado']" bottom>
                                    <span>
                                      <v-icon color="warning">mdi-alert</v-icon>
                                    </span>
                                  </TooltipErrors>
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </td>
                          <td class="text-center">
                            {{ work_unit.unit }}
                          </td>
                          <td class="text-center" v-if="!locked_edition">
                            <v-col cols="12">
                              <div :class="{ shake: shakeWorkHandButtons }">
                                <v-btn width="100px" dark small color="secondary"
                                  @click="showWorkersWorkUnitsDialog(work_unit)">
                                  <small>Mão de Obra</small>
                                  <v-icon small>mdi-account-hard-hat</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                            <v-col cols="12" class="mt-n2">
                              <div :class="{ shake: shakeMachineButtons }">
                                <v-btn :disabled="fields.machines.length == 0" width="100px" dark small color="secondary"
                                  @click="showMachinesWorkDialog(work_unit)">
                                  <small>Máquinas</small>
                                  <v-icon small>mdi-bulldozer</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                            <v-col cols="12" class="mt-n2">
                              <v-btn width="100px" dark small color="error" @click="removeWorkUnit(work_unit.id)" v-if="fields.approvals == undefined ? true : fields.approvals.topics.unidades_obra == false">
                                <small>Eliminar</small>
                                <v-icon small>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </td>
                          <td v-else></td>
                          <td class="text-caption text-center"
                            v-if='method == "update" && $root.session.hasPermission(["super", "diary.access_special_view"])'>
                            {{ convertNumber(work_unit.measured_acumulated / work_unit.measure_budgeted) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-data-iterator :items="fields.work_units" item-key="id" :items-per-page="-1" hide-default-footer
                    class="d-block d-lg-none">
                    <template v-slot:default="{ items }">
                      <v-row>
                        <v-col v-for="work_unit in items" :key="work_unit.id" cols="12" sm="6" md="4" lg="3">
                          <v-card>
                            <v-list dense>
                              <v-list-item>
                                <v-list-item-content>Nome:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="action_name" name="Unidade Obra"
                                    rules="required">
                                    <v-combobox
                                      v-model="work_unit.name"
                                      :disabled="fields.approvals == undefined ? false : fields.approvals.topics.unidades_obra == true"
                                      label="Unidade Obra *"
                                      :filter="filterAutocomplete" :items="units"
                                      @input="work_unit.code = $event.code; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                                      item-value="id" item-text="name" hide-selected :error-messages="errors">

                                      <template v-slot:prepend>
                                        <TooltipErrors v-if="!validWorkUnit(work_unit).result" color="error"
                                          :messages="validWorkUnit(work_unit).errors" bottom>
                                          <v-icon color="error">mdi-alpha-x-circle</v-icon>
                                        </TooltipErrors>
                                        <v-icon color="success" v-else>mdi-check-bold</v-icon>
                                      </template>

                                    </v-combobox>
                                  </validation-provider>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item>
                                <v-list-item-content>Texto:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="reason" name="Texto livre">
                                    <v-text-field v-model="work_unit.reason" label="Texto livre" class="align-end"
                                      @click="openEditorModal(work_unit, fields, 'unidades_obra')" :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-list-item-content>
                              </v-list-item>



                              <v-list-item>
                                <v-list-item-content>Código:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  {{ work_unit.code }}
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Medição:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <validation-provider v-slot="{ errors }" vid="measure" name="Medição *"
                                    rules="required">
                                    <v-text-field
                                      :disabled="fields.approvals == undefined ? false : fields.approvals.topics.unidades_obra == true"
                                      @input="handleCommaToDot(work_unit, 'measure')"
                                      v-model="work_unit.measure" label="Medição *"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Unidade:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  {{ work_unit.unit }}
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Mão de Obra:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-btn dark small block color="secondary"
                                    @click="showWorkersWorkUnitsDialog(work_unit)">
                                    <v-icon small>mdi-account-hard-hat</v-icon>
                                  </v-btn>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Máquinas:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-btn dark small block color="secondary" @click="showMachinesWorkDialog(work_unit)">
                                    <v-icon small>mdi-bulldozer</v-icon>
                                  </v-btn>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>Opções:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                  <v-btn dark small color="error" @click="removeWorkUnit(work_unit.id)" v-if="fields.approvals == undefined ? true : fields.approvals.topics.unidades_obra == false">
                                    <v-icon small>mdi-delete</v-icon>
                                  </v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                  <v-row v-if="!locked_edition" id="machines" class="mt-7">
                    <div style="position: relative; width: 100%; display: flex; justify-content: center;">

                      <!-- Botão + centralizado no quadrado vermelho -->
                      <v-btn v-if="fields.approvals == undefined ? true : fields.approvals.topics.unidades_obra == false" fab dark small color="primary" @click="addWorkUnit">
                        <v-icon small>mdi-plus</v-icon>
                      </v-btn>

                    </div>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-show="diaryCreationPossible">
                <v-col cols="12">
                  <v-textarea dense outlined v-model="fields.description" label="Texto Livre" prepend-inner-icon="mdi-card-text"
                    counter="900" maxlength="900" clearable></v-textarea>
                </v-col>
              </v-row>
              <div class=" d-none d-lg-block" v-if="diaryCreationPossible">
                <v-row class="mt-6" justify="space-around">
                  <v-btn v-if="$route.name != 'create' && $route.name != 'Offline Diary'" :disabled="invalid || locked_edition" depressed color="warning"
                    @click="tab = 1">
                    Inserir imagens
                  </v-btn>
                  <!--
                  <v-btn v-if="$route.name != 'create' && $route.name != 'Offline Diary'" :disabled="invalid || locked_edition" depressed color="success"
                    @click="showApprovalWindow">
                    Aprovar
                  </v-btn>
                  -->
                  <v-btn :disabled="invalid || locked_edition" depressed color="primary" type="submit">
                    Gravar
                  </v-btn>

                  <Reject v-if="$route.name != 'create' && $route.name != 'Offline Diary' && locked_edition == false" :opened="rejectDiary" label="Escreva o motivo da sua rejeição" vid="reject_modal"
                    title="Insira a razão da rejeição" />

                  <v-menu offset-y v-if="$route.name != 'create' && $route.name != 'Offline Diary'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :disabled="fields.status == 'FINISHED_APPROVAL' ? false : invalid" depressed outlined color="danger" v-bind="attrs" v-on="on">
                        Imprimir
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="exportDiary('Excel')">
                        <v-list-item-title>Excel</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="exportDiary('PDF')">
                        <v-list-item-title>PDF</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                </v-row>
              </div>
              <!-- BTNS MOBILE-->
              <div class="d-block d-lg-none" v-if="diaryCreationPossible">
                <v-row class="mt-4" align="center" justify="space-around">
                  <v-btn v-if="$route.name != 'create'" :disabled="invalid || locked_edition" depressed color="warning"
                    @click="tab = 1">
                    Inserir imagens
                  </v-btn>
                </v-row>
                <v-row class="mt-8" align="center" justify="space-around">
                  <v-btn v-if="$route.name != 'create'" :disabled="invalid || locked_edition" depressed color="success"
                    @click="showApprovalWindow">
                    Aprovar
                  </v-btn>

                </v-row>
                <v-row class="mt-8" align="center" justify="space-around">
                  <v-btn :disabled="invalid || locked_edition" depressed color="primary" type="submit">
                    Gravar
                  </v-btn>

                </v-row>
                <v-row class="mt-8" align="center" justify="space-around">
                  <v-btn v-if="$route.name != 'create'"
                    :disabled="$root.session.hasRole(['encarregado']) || invalid || locked_edition" depressed
                    color="red" @click="rejectDiary = true">
                    Rejeitar
                  </v-btn>
                </v-row>
                <v-row class="mt-8" align="center" justify="space-around">
                  <v-menu offset-y v-if="$route.name != 'create'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :disabled="invalid" depressed outlined color="danger" v-bind="attrs" v-on="on">
                        Imprimir
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="exportDiary('Excel')">
                        <v-list-item-title>Excel</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="exportDiary('PDF')">
                        <v-list-item-title>PDF</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
              </div>
              <!-- BTNS MOBILE-->
            </v-form>
          </validation-observer>
        </v-container>
        <DialogSuccess :opened="success" @on-ok="onSuccess">
          Diário gravado com sucesso!
        </DialogSuccess>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="2">
        <v-btn v-if="method == 'update'" class="mb-4 mt-4 mr-2" style="float:right;" dark color="secondary"
          @click="downloadImages()">
          Descarregar tudo
          <v-icon class="ml-2">mdi-briefcase-download</v-icon>
        </v-btn>
        <div class="d-flex">
          <VueUploadMultipleImage ref="imageUploader" class="image-uploader" dragText="Inserir imagens"
            browseText="Clique aqui" :showPrimary="false" @upload-success="uploadImageSuccess"
            @before-remove="beforeRemove" :data-images="images" idUpload="myIdUpload" editUpload="myIdEdit"
            :maxImage="200" :disabled="locked_edition" />
        </div>
        <DialogConfirmation title="Remover Imagem" type="warning" v-if="openConfirmationRemoveImage"
          :opened="openConfirmationRemoveImage" @on-submit="submitRemoveImage"
          @on-cancel="openConfirmationRemoveImage = false">
          Tem a certeza que pretende remover a imagem?
        </DialogConfirmation>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="3" v-if="method == 'update'">
        <DiaryArticle :work_id="fields.work_id"/>
      </v-tab-item>
      <v-tab-item class="mt-6" :key="4" v-if="method == 'update'">
        <StoryBook />
      </v-tab-item>
    </v-tabs-items>
    <v-row justify="center">
      <v-dialog v-model="showingWorkers" max-width="800px">
        <!--<MaoObra
          v-if="showingWorkers"
          v-show="showAutoWorkers"
          ref="MaoObraModal"
          :diary_status="diary_status"
          :locked_edition="locked_edition"
          :fields="fields"
          :workers_auto="workers_auto"
          :work_unit_id="work_unit_id+''"
          :is_error="alerts.workHandAlert"
          :divideAll="fields.divideAllWorkHand"
          @close="showingWorkers = false"
          @close-with-work="closeWithWork"
          @reload="reload"
          return-object
        /> -->

        <WorkHandDivision v-if="showingWorkers" :fields="fields" :locked_edition="locked_edition"
          :work_unit_id="selectedWorkUnit.id" :auto_divider="fields.divideAllWorkHand" :workers="workers"
          @submit="workHandSubmission" />
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showingMachines" max-width="800px">
        <!--<Machines
          v-if="showingMachines"
          v-show="showAutoMachines"
          :diary_status="diary_status"
          :locked_edition="locked_edition"
          :fields="fields"
          :machines_auto="machines_auto"
          :work_unit_id="work_unit_id+''"
          :is_error="alerts.machineAlert"
          :divideAll="fields.divideAllMachines"
          @close="showingMachines = false"
          @close-with-work="closeWithMachineWork"
          @reload="reload"
          return-object
        />-->
        <MachineWorkDivision v-if="showingMachines" :fields="fields" :locked_edition="locked_edition"
          :work_unit_id="selectedWorkUnit.id" :auto_divider="fields.divideAllMachines"
          @submit="machineHandSubmission" />
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="breakdown.show" max-width="800px">
        <Breakdown v-if="breakdown.show" @close-success="breakdown.show = false; breakdown.machine.status = 'Avaria'"
          @breakdown="newBreakdown" :fields="fields" :machine="breakdown.machine" />
      </v-dialog>
    </v-row>
    <ErrorSnackbar v-model="error.show" :message="error.message" />
    <WarningSnackbar v-model="warning.show" :message="warning.message" />
    <ExtensiveErrorSnackbar v-model="error.showExtensive" :message="error.message" />
    <v-dialog v-if="editorModal" v-model="editorModal" max-width="600px" scrollable>
      <ModalTextArea :locked="locked_edition" @change-value="changeModalTextArea" vid="editor_local"
        :title="'Texto livre'" :label="'Texto livre'" v-model="inputEditorModalData.itemModel" :editable="inputEditorModalData.editable"/>
    </v-dialog>
    <DialogConfirmation v-if="checkApproval"
      title="Qualquer alteração realizada irá ser guardada e a diária aprovada. Confirme a ação" type="info"
      :opened="checkApproval" @on-submit="startApproval" @on-cancel="checkApproval = false">
      Tem a certeza que deseja aprovar?
    </DialogConfirmation>
    <DialogConfirmation v-if="dieselObject.emptyDiesel" title="Campos de diesel" type="warning"
      :opened="dieselObject.emptyDiesel" @on-submit="passDiesel"
      @on-cancel="loading = false; dieselObject.emptyDiesel = false">
      Tem valores de diesel vazios. Pretende prosseguir?
    </DialogConfirmation>
    <DialogConfirmation v-if="divideHours.show" :title="divideHours.title" type="warning" :opened="divideHours.show"
      @on-submit="dividirHorasTodos" @on-cancel="loading = false; divideHours.show = false">
      {{ divideHours.msg }}
    </DialogConfirmation>

    <DialogSuccess :opened="successApproved" @on-ok="approvedSuccess">
      Diário aprovado com sucesso!
    </DialogSuccess>

  </div>
</template>
<script>
import StoryBook from '@/components/diaries/story/StoryBook.vue'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import WarningSnackbar from '@/components/ui/WarningSnackbar.vue';
import ExtensiveErrorSnackbar from '@/components/ui/ExtensiveErrorSnackbar.vue';
import Work from "@/api/Work.js";
import Diary from "@/api/Diary.js";
import Worker from "@/api/Worker.js";
import System from "@/api/System.js"
import QRCode from '@/api/QRCode.js'
//import DiaryOffline from '@/api/DiaryOffline.js'
import Export from "@/api/Export.js";
import WorkUnitList from '@/api/WorkUnitList.js'
import Machine from "@/api/Machine.js";
import Unit from "@/api/Unit.js";
import dayjs from 'dayjs'
import Select from '@/components/inputs/Select'
import ModalTextArea from '@/components/ui/ModalTextArea.vue';
import Downloads from "@/api/Downloads.js";
import Reject from '@/components/diaries/dialogs/Reject'

import WorkHandDivision from '@/components/diaries/dialogs/WorkHandDivision'
import MachineWorkDivision from '@/components/diaries/dialogs/MachineWorkDivision'
import TooltipErrors from '@/components/ui/TooltipErrors'
import Tooltip from '@/components/ui/Tooltip'
import Breakdown from '@/components/diaries/dialogs/Breakdown'
import { saveDiaryOffline } from '../../idb'
import Category from "@/api/Category.js";
import BtnApproveDiary from '@/components/ui/DiaryApproveTopic.vue'
import BtnDisapprove from '@/components/diaries/approvals/Disapprove.vue'
import DiaryArticle from '@/components/diaries/articles/DiaryArticle.vue'

export default {
  components: {
    DiaryArticle,
    DialogSuccess,
    VueUploadMultipleImage,
    DialogConfirmation,
    ErrorSnackbar,
    Select,
    ModalTextArea,
    BtnDisapprove,
    Reject,
    ExtensiveErrorSnackbar,
    WarningSnackbar,
    BtnApproveDiary,
    WorkHandDivision,
    MachineWorkDivision,
    TooltipErrors,
    Tooltip,
    Breakdown,
    StoryBook
  },
  props: {
    diary: Object,
    method: String,
    disabled: Boolean,
  },
  watch: {
    machines: 'checkVars',
    adblueAveragePrice: 'checkVars',
    dieselAveragePrice: 'checkVars',
    diary: {
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }

        let data = { ...val };

        this.fields = data;

        this.fields.date = dayjs(this.fields.date).format("YYYY-MM-DD");

        this.fields.id = data.id;

        this.fixWorkUnitLineNumber()

        this.diary_status = data.status;

        this.buildImages(data)

        Category.search().then(response => {

          let data = response.data;

          this.total = data.total;

          this.items = data.data.map((item) => {

            this.searching = false;

            return item;
          });

          this.loading = false;

        });

        this.fields.divideAllMachines = false
        this.fields.divideAllWorkHand = false
        this.fields.breakdown_array = []

        let params = {
          date: this.fields.date
        }

        Machine.list(params).then(({ data }) => {
          this.machines = data;         
        });

        if (this.fields.work_front_id != null)
          this.checkWorkFronts()

        if (this.diary.status == 'FINISHED_APPROVAL')
          this.locked_edition = true

        if(this.$root.session.hasRole(['consulta']))
          this.locked_edition = true

      }
    },
  },
  data: () => ({
    dieselAveragePrice: null,
    adblueAveragePrice: null,
    work_fronts: [],
    enableWorkFronts: false,
    isToApprove: true,
    budgeted_units: null,
    reloadCombo: 895,
    reloadUnidadeObra: 9999,
    machines_auto: false,
    showAutoMachines: false,
    breakdown: {
      show: false,
      machine: null
    },
    divideHours: {
      show: false,
      title: '',
      type: '',
      machines: false,
      workHand: false
    },
    successApproved: false,
    showAutoWorkers: false,
    workers_auto: false,
    shakeWorkHandButtons: false,
    shakeMachineButtons: false,
    diaryCreationPossible: false,
    alerts: {
      machineAlert: false,
      workHandAlert: false,
    },
    keyReason: 0,
    refreshComponent: false,
    locked_edition: false,
    dieselObject: {
      ignoreDiesel: false,
      emptyDiesel: false
    },
    rejectDiary: false,
    generalKey: 0,
    editorModal: false,
    inputEditorModalData: {
      itemModel: null,
      id: null,
      object: null,
      editable: true
    },
    images: [],
    templateField: null,
    useTemplate: false,
    blockTemplate: false,
    blockReplicar: true,
    replicar: false,
    newDiary: false,
    renderWorkedHours: 0,
    renderMachineWorkedHours: 0,
    tab: 0,
    workers: [],
    work_units: [],
    work_unit_id: null,
    showingWorkers: false,
    showingMachines: false,
    diary_status: null,
    qrcodeWorkerData:[],
    fields: {
      id: null,
      work_id: null,
      code: null,
      date: null,
      work_front_id: null,
      workers: [],
      work_units: [],
      machines: [],
      name: null,
      status: null,
      description: null,
      started_at: null,
      finished_at: null,
      commissioner_id: null,
      overseer_id: null,
      engineer_id: null,
      files: null,
      newWorkUnit: null,
      machinesWorkUnit: null,
      divideAllWorkHand: false,
      divideAllMachines: false,
      machine_work_units: [],
      worker_work_units: [],
      breakdown_array: []
    },
    warning: {
      show: false,
      title: '',
      message: ''
    },
    filesFormData: [],
    loading: false,
    loadingAction: false,
    error: {
      title: '',
      message: '',
      show: false,
      showExtensive: false
    },
    status: [],
    works: [],
    machines: [],
    uploadImages: [],
    success: false,
    date: false,
    units: [],
    openConfirmationRemoveImage: false,
    fileIndex: [],
    fileList: [],
    checkApproval: false,
    // NEW VERSION
    selectedWorkUnit: null,
    removedWorkers: [],
  }),
  async mounted() {
    if(this.$route.name == 'Offline Diary')
      this.enableWorkFronts = false;

    this.fillBaseData();

    let work_id = this.$route.params.work_id
    let start_date = this.$route.params.start_date

    if (this.$route.name == 'create' && work_id != undefined && start_date != undefined) {
      
      this.loading = true
      this.fields.work_id = work_id
      await this.setDiaryCode(work_id)
      this.fields.date = start_date
      this.diaryCreationPossible = true
      this.loading = false
      return
    }

    if (this.$route.params.id != undefined)
      this.diaryCreationPossible = true

      if(this.$route.name == 'Offline Diary'){
        this.fields.divideAllWorkHand = this.diary.divideAllWorkHand
        if(this.fields.divideAllWorkHand)
          this.changeCheckDivider('maodeobra')
        this.fields.divideAllMachines = this.diary.divideAllMachines
        if(this.fields.divideAllMachines)
          this.changeCheckDivider('maquinas')
      }
  },
  methods: {  
    handleCommaToDot(machine, field) {
      if (machine[field]) {
        // Substituir todas as vírgulas por pontos
        machine[field] = machine[field].toString().replace(',', '.');
      }
    },  
    checkVars(){
      if (this.machines && this.adblueAveragePrice && this.dieselAveragePrice) {
        let i = 0
        this.fields.machines.forEach(element => {
          this.machineChanged(element, i, false)
          i++
        });
      }        
    },
    convertNumber(n){
      if(n == null) return 0;
      if(n == undefined) return 0;

      return n.toFixed(2)
    },
    importQRCodeMO(){
      let parent = this
      this.qrcodeWorkerData.forEach(element => {

        // Primeiro verificar se este worker já está lá inserido
        let exists = false
        this.fields.workers.forEach(field_worker => {
          if(field_worker.id == element.worker.id)
            exists = true
        });

        if(exists == false){
          console.log(element)

          let hora_inicio = element.hora_entrada + ':' + element.minuto_entrada;
          let hora_saida = element.hora_saida + ':' + element.minuto_saida;

          // Converter as horas para objetos Date
          let [horaInicio, minutoInicio] = hora_inicio.split(':').map(Number);
          let [horaSaida, minutoSaida] = hora_saida.split(':').map(Number);

          let dataEntrada = new Date();
          dataEntrada.setHours(horaInicio, minutoInicio, 0, 0); // Hora de entrada

          let dataSaida = new Date();
          dataSaida.setHours(horaSaida, minutoSaida, 0, 0); // Hora de saída

          // Verificar se a saída é no dia seguinte
          if (dataSaida < dataEntrada) {
              dataSaida.setDate(dataSaida.getDate() + 1); // Adicionar um dia
          }

          // Calcular a diferença em milissegundos e converter para horas
          let workedMilliseconds = dataSaida - dataEntrada;
          let workedHours = workedMilliseconds / (1000 * 60 * 60); // Converter para horas

          workedHours = workedHours - 1

          parent.fields.workers.push({
            id: element.worker.id,
            name: element.worker.name,
            started_hour: element.hora_entrada,
            started_minute: element.minuto_entrada,
            finished_hour: element.hora_saida,
            finished_minute: element.minuto_saida,
            slept_night: false,
            night_work: false,
            breakfast: false,
            lunch: true,
            worked_hours: workedHours,
            cost: (element.worker.value * workedHours).toFixed(2),
            object: element.worker
          });
        }
        
      });      
    },
    QRCodehasData(){
      let params = {
        work_id: this.fields.work_id,
        work_front_id: this.fields.work_front_id,
        date: this.fields.date
      }
      QRCode.getData(params).then((resp)  =>  {
        this.qrcodeWorkerData = resp.data
        if(this.qrcodeWorkerData.length > 0){
          this.importQRCodeMO()
        }
      })
    },
    onlyNumbers(event) {
        const value = event.target.value;
        if (value.includes('.') || value.includes(',')) {
          const decimalPlaces = value.split('.')[1] || value.split(',')[1];
          if (decimalPlaces && decimalPlaces.length >= 2) {
            event.preventDefault();
          }
        }
        if (event.key === ',' || event.key === '.') {
          if (value.includes('.') || value.includes(',')) {
            event.preventDefault();
          }
        } else if (isNaN(event.key)) {
          event.preventDefault();
        }
    },
    contaHorasAtivo(item){
      if(item == null)
        return true;

      if(item.object == null)
        return true

      if(item.object.counter_type != 'SEM_CONTADOR')
        return true

      return false
    },
    disapproveTopic(params){
      let data = {
        id: this.$route.params.id,
        topic: params.topic,
        explanation: params.explanation
      }


      Diary.disapproveTopic(data).then(()  =>  {
        this.$emit('simpleReload')
      })
    },
    approveTopic(topic){
      let data = {
        id: this.$route.params.id,
        topic: topic
      }

      Diary.approveTopic(data).then(()  =>  {
        this.$emit('simpleReload')
      })
    },
    roundValue(v){
      return v.toFixed(2)
    },
    approvedSuccess(){
      this.successApproved = false;
      window.location.reload()
    },
    pernoitaChanged(w) {
      if (w.slept_night)
        w.breakfast = true
    },
    checkBudgeted(work_unit) {
      let flag = false
      if (this.budgeted_units == null)
        return flag;

      this.budgeted_units.forEach(element => {
        if (element.id_work_unit == work_unit.unit_id) {
          let percentagem = element.budgeted_income * 0.2;
          let total = percentagem + element.budgeted_income

          if (work_unit.measure >= total)
            flag = true
        }
      });
      return flag
    },
    machineChanged(item, index, started_hours = true) {
      
      this.machines.forEach(element => {
        if (element.id == item.id){
          let maquina = this.fields.machines[index]

          if(maquina.finished_hours != null && maquina.started_hours != null){
            let diferenca = maquina.finished_hours - maquina.started_hours;

            // Temos de verificar qual a unidade de custo. Se for ao dia, o custo é diário e não por KM
            if(element.machine_unit === 'dia') diferenca = 1;
            
            let total = diferenca * element.value
            // Preço do adblue
            if(element.uses_ad_blue){
              let adblue_custo = this.adblueAveragePrice == null ? 0 : parseFloat(this.adblueAveragePrice) * parseFloat(maquina.ad_blue)
              total = total + adblue_custo
            }
            // Preço do diesel
            if(element.uses_diesel){
              let diesel_custo = this.dieselAveragePrice == null ? 0 : parseFloat(this.dieselAveragePrice) * parseFloat(maquina.diesel)
              total = total + diesel_custo
            }

            if(total < 0)
              total = 0;

            this.fields.machines[index].horas_trabalhadas = diferenca
            this.fields.machines[index].cost = total.toFixed(2)
          }
          
          this.fields.machines[index].uses_diesel = element.uses_diesel
          this.fields.machines[index].uses_ad_blue = element.uses_ad_blue
          this.fields.machines[index].id_company = element.company == null ? null : element.company.id

          if(started_hours)
            this.fields.machines[index].started_hours = element.next_start == null ? 0 : element.next_start
        }
      });
      this.usedMachine(item)
    },
    changedWorker(item, index) {
      this.workers.forEach(element => {
        if (item.id == element.id) {
          this.fields.workers[index].id_company = element.company == null ? null : element.company.id;

          let ob = this.fields.workers[index];
          let horas_trabalhadas = this.$calcularHorasTrabalhadas(ob.started_hour, ob.started_minute, ob.finished_hour, ob.finished_minute);

          // Calculando o tempo total trabalhado, ajustado para subtrair a hora de almoço
          let tempo_trabalhado = horas_trabalhadas.hours + (horas_trabalhadas.minutes / 60) - element.lunch_hour;

          // Calculando o custo básico para 8 horas ou menos
          let total = Math.min(tempo_trabalhado, 8) * element.custo_hora;

          // Cálculo do custo adicional para horas extras
          if (tempo_trabalhado > 8) {
            const horas_extras = tempo_trabalhado - 8;

            // Aplicar custo extra para cada hora adicional individualmente
            if (horas_extras >= 1) {
              total += 1 * element.custo_hora * 1.5; // Primeira hora extra a 50%
            }
            if (horas_extras >= 2) {
              total += 1 * element.custo_hora * 1.75; // Segunda hora extra a 75%
            }
            if (horas_extras > 2) {
              total += (horas_extras - 2) * element.custo_hora * 2; // Terceira hora extra e seguintes a 100%
            }
          }

          this.fields.workers[index].horas_trabalhadas = tempo_trabalhado;
          this.fields.workers[index].cost = total.toFixed(2);
        }
      });
    },
    leftAverage(machine) {
      if (machine.started_hours == null)
        return false;
      if (machine.finished_hours == null)
        return false;

      let valor = machine.finished_hours - machine.started_hours

      if (machine.average_km == 0)
        return false;

      if (valor == 0)
        return false;

      if(machine.manual_average_km != null){
        if(valor > machine.manual_average_km)
          return true;
      }

      let leftAverage = machine.average_km * 2

      if (valor > leftAverage)
        return true

      return false
    },
    newBreakdown(item) {
      let pos = -1
      let i = 0
      this.fields.machines.forEach(element => {
        if (element.id == item.machine_id) {
          pos = i
        }
        i++
      });

      if (pos != -1) {
        this.fields.machines[pos].status = 'Avaria'
        this.fields.machines[pos].machine_diary_break = item.breakdown
        this.reloadCombo++
        this.fields.breakdown_array.push(item)
      }
    },
    machineBreakdown(machine) {
      this.breakdown.machine = machine
      this.breakdown.show = true
    },
    validWorkUnit(wu) {
      let returner = {
        result: true,
        errors: []
      };


      // Validar unidade obra
      if (wu.name == '' || wu.name == null || wu.name == undefined) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes("Unidade de Obra não selecionada"))
          returner.errors.push("Unidade de Obra não selecionada")
        returner.result = false
      }

      // Validar unidade obra
      if (wu.code == '' || wu.code == null || wu.code == undefined) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes("Unidade de Obra não selecionada"))
          returner.errors.push("Unidade de Obra não selecionada")
        returner.result = false
      }

      // Validar medição
      if (wu.measure + '' == '' || wu.measure == null || wu.measure == undefined) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes("Medição em falta"))
          returner.errors.push("Medição em falta")
        returner.result = false
      }

      // Validar Mão de Obra
      if (this.fields.divideAllWorkHand == false) {
        if (this.fields.worker_work_units.length == 0) {
          // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
          if (!returner.errors.includes("Falta definir a mão de obra"))
            returner.errors.push("Falta definir a mão de obra")
          returner.result = false
        } else {
          // caso não seja de tamanho zero, significa que tem dados
          // então vamos percorrer os colaboradores
          // e verificar se estes têm as horas inseridas
          // se não encontrou nenhum é porque está em falta
          let found = false
          this.fields.worker_work_units.forEach(element => {
            if (element.unit_id == wu.unit_id) {
              // Encontrei um registo, não há problemas
              found = true;
              if (element.worked_hours + '' == '' || element.worked_hours == null || element.worked_hours == undefined) {
                // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
                if (!returner.errors.includes("Falta definir a mão de obra"))
                  returner.errors.push("Falta definir a mão de obra")
                returner.result = false
              }
            }
          });
          // caso não tenha encontrado nenhum registo, está em falta. Temos de lançar a mensagem
          if (found == false) {
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes("Falta definir a mão de obra"))
              returner.errors.push("Falta definir a mão de obra")
            returner.result = false
          }
        }
      }



      // Validar Máquinas
      if (this.fields.divideAllMachines == false) {
        if (this.fields.machines.length > 0) {
          if (this.fields.machine_work_units.length == 0) {
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes("Falta definir horas de máquina"))
              returner.errors.push("Falta definir horas de máquina")
            returner.result = false
          } else {
            // caso não seja de tamanho zero, significa que tem dados
            // então vamos percorrer os colaboradores
            // e verificar se estes têm as horas inseridas
            // se não encontrou nenhum é porque está em falta
            let found = false
            this.fields.machine_work_units.forEach(element => {
              if (element.unit_id == wu.unit_id) {
                // Encontrei um registo, não há problemas
                found = true;
                if (element.worked_hours + '' == '' || element.worked_hours == null || element.worked_hours == undefined) {
                  // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
                  if (!returner.errors.includes("Falta definir horas de máquina"))
                    returner.errors.push("Falta definir horas de máquina")
                  returner.result = false
                }
              }
            });
            // caso não tenha encontrado nenhum registo, está em falta. Temos de lançar a mensagem
            if (found == false) {
              // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
              if (!returner.errors.includes("Falta definir horas de máquina"))
                returner.errors.push("Falta definir horas de máquina")
              returner.result = false
            }
          }
        }
      }


      // Se tiver máquinas, então tem de ter registo das máquinas também
      if (this.fields.divideAllMachines == false) {
        if (this.fields.machines.length > 0) {
          if (this.fields.machine_work_units.length == 0) {
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes("Falta definir horas de máquina"))
              returner.errors.push("Falta definir horas de máquina")
            returner.result = false
          }
        }
      }


      return returner
    },
    validWorkHand(hand) {
      let returner = {
        result: true,
        errors: []
      };


      if (hand.started_hour == null || hand.started_hour == undefined) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes("Verifique a hora e minutos de entrada"))
          returner.errors.push("Verifique a hora e minutos de entrada")
        returner.result = false
      } else {
        if (typeof hand.started_hour == "string") {
          if (hand.started_hour == '') {
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes("Verifique a hora e minutos de entrada"))
              returner.errors.push("Verifique a hora e minutos de entrada")
            returner.result = false
          }
        }
      }

      if (hand.started_minute == null || hand.started_minute == undefined) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes("Verifique a hora e minutos de entrada"))
          returner.errors.push("Verifique a hora e minutos de entrada")
        returner.result = false
      } else {
        if (typeof hand.started_minute == "string") {
          if (hand.started_minute == '') {
            returner.errors.push("Verifique a hora e minutos de entrada")
            returner.result = false
          }
        }
      }

      if (hand.finished_hour == null || hand.finished_hour == undefined) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes('Verifique a hora e minutos de saída'))
          returner.errors.push('Verifique a hora e minutos de saída')
        returner.result = false
      } else {
        if (typeof hand.finished_hour == "string") {
          if (hand.finished_hour == '') {
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes('Verifique a hora e minutos de saída'))
              returner.errors.push('Verifique a hora e minutos de saída')
            returner.result = false
          }
        }
      }

      if (hand.finished_minute == null || hand.finished_minute == undefined) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes('Verifique a hora e minutos de saída'))
          returner.errors.push('Verifique a hora e minutos de saída')
        returner.result = false
      } else {
        if (typeof hand.finished_minute == "string") {
          if (hand.finished_minute == '') {
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes('Verifique a hora e minutos de saída'))
              returner.errors.push('Verifique a hora e minutos de saída')
            returner.result = false
          }
        }
      }

      if (hand.id == "new" || hand.id == undefined || hand.id == null || hand.id.includes('new')) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes('Falta definir a mão de obra'))
          returner.errors.push('Falta definir a mão de obra')
        returner.result = false
      }

      return returner;
    },
    showMachinesWorkDialog(selectedWorkUnit) {
      this.selectedWorkUnit = selectedWorkUnit

      this.showingMachines = true
    },
    machineHandSubmission(machine_hands) {
      machine_hands.forEach(element_machines => {
        let pos = -1
        let i = 0

        this.fields.machine_work_units.forEach(mwu => {
          if (mwu.work_unit_id == element_machines.work_unit_id && mwu.machine_id == element_machines.machine_id)
            pos = i

          i++
        });

        // Se pos == -1 então não encontrou nada, temos de criar um registo novo
        // se diferente de -1, é porque existe e temos de atualizar o registo atual
        if (pos == -1) {
          this.fields.machine_work_units.push({
            id: 'new_' + element_machines.machine_id,
            line_number: this.fields.machine_work_units.length,
            unit_id: this.selectedWorkUnit.unit_id,
            work_unit_id: this.selectedWorkUnit.id,
            machine_id: element_machines.machine_id,
            worked_hours: element_machines.worked_hours
          })
        } else {
          this.fields.machine_work_units[pos].worked_hours = element_machines.worked_hours
        }

      });
      this.showingMachines = false
    },
    showWorkersWorkUnitsDialog(selectedWorkUnit) {
      this.selectedWorkUnit = selectedWorkUnit

      this.showingWorkers = true
    },
    workHandSubmission(work_hands) {
      work_hands.forEach(element_hands => {
        let pos = -1
        let i = 0

        this.fields.worker_work_units.forEach(wwu => {
          if (wwu.work_unit_id == element_hands.work_unit_id && wwu.worker_id == element_hands.worker_id)
            pos = i

          i++
        });

        // Se pos == -1 então não encontrou nada, temos de criar um registo novo
        // se diferente de -1, é porque existe e temos de atualizar o registo atual
        if (pos == -1) {
          this.fields.worker_work_units.push({
            id: 'new_' + element_hands.worker_id,
            line_number: this.fields.worker_work_units.length,
            unit_id: this.selectedWorkUnit.unit_id,
            work_unit_id: this.selectedWorkUnit.id,
            worker_id: element_hands.worker_id,
            worked_hours: element_hands.worked_hours
          })
        } else {
          this.fields.worker_work_units[pos].worked_hours = element_hands.worked_hours
        }

      });

      this.showingWorkers = false
      this.reloadUnidadeObra++
    },
    async showApprovalWindow() {
      let validation = await this.validateFormFields()

      // Não pode ser aprovada
      // Validações falhadas
      if (validation == false)
        return
      else
        this.checkApproval = true
    },
    async startApproval() {
      // Verificar se há niveis de diesel a zero
      // Se houver temos de perguntar se quer continuar
      let diesel = false; //await this.checkEmptyDiesel()

      if (diesel == true) {
        this.isToApprove = true
        return
      } else {
        await this.approve()
      }

    },
    async usedMachine(machine){
      await Machine.checkAvailableMachine({machine_id: machine.id, date: this.fields.date}).then((resp) =>  {


        machine.warning = resp.data.exists
      })
    },
    validMachine(machine) {
      let returner = {
        result: true,
        errors: []
      };

      let machine_id = machine.id + ''
      if (machine_id.includes('new') || machine.id == '' || machine.id == null || machine.id == undefined) {
        // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
        if (!returner.errors.includes('Máquina em falta'))
          returner.errors.push('Máquina em falta')

        returner.result = false
      }

      if (machine.uses_diesel) {
        if (machine.diesel == null || machine.diesel + '' == '' || machine.diesel == undefined) {
          // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
          if (!returner.errors.includes('Diesel em falta'))
            returner.errors.push('Diesel em falta')
          returner.result = false
        }
      }

      if (machine.uses_ad_blue) {
        if (machine.ad_blue == null || machine.ad_blue + '' == '' || machine.ad_blue == undefined) {
          // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
          if (!returner.errors.includes('Adblue em falta'))
            returner.errors.push('Adblue em falta')
          returner.result = false
        }
      }

      if (machine.started_hours == null || machine.started_hours == undefined) {
        if(machine.object != null){
          if(machine.object.counter_type != 'SEM_CONTADOR'){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes('Horas de ínicio em falta'))
              returner.errors.push('Horas de ínicio em falta')
            returner.result = false
          }
        }
      }

      if (machine.finished_hours == null || machine.finished_hours == undefined) {
        if(machine.object != null){
          if(machine.object.counter_type != 'SEM_CONTADOR'){
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes('Horas de fim em falta'))
              returner.errors.push('Horas de fim em falta')
            returner.result = false
            }
        }
      }


      if (parseFloat(machine.started_hours) > parseFloat(machine.finished_hours)) {
            // Para não repetir mensagens, só adicionamos se ela ainda não estiver lá
            if (!returner.errors.includes('Hora de ínicio não pode ser maior que a de fim'))
              returner.errors.push('Hora de ínicio não pode ser maior que a de fim')
            returner.result = false
      }


      return returner;
    },
    changeCheckDivider(e) {
      if (e == 'maquinas')
        if (this.fields.divideAllMachines)
          this.warning.message = "Horas de máquina serão divididas automaticamente pelo sistema"
        else
          this.warning.message = "Horas de máquina serão dividadas manualmente. Não se esqueça de as definir antes de submeter a diária"
      else
        if (this.fields.divideAllWorkHand)
          this.warning.message = "Horas de mão de obra serão divididas automaticamente pelo sistema"
        else
          this.warning.message = "Horas de mão de obra serão divididas manualmente. Não se esqueça de as definir antes de submeter a diária"

      this.warning.show = true
    },
    verifyWorker(worker) {
      let data = {
        worker_id: worker.id,
        data: this.fields.date
      }

      Worker.workerInDiary(data).then((response) => {
        let ar = response.data
        if (ar.length > 0) {
          this.error.message = 'O utilizador escolhido já se encontra registado em uma diária para o mesmo dia'
          this.error.showExtensive = true
          this.removeWorker(worker.id)
        }
      });
    },
    changedData() {
      this.checkDiaryCreation()

      let params = {
        date: this.fields.date
      }

      Machine.list(params).then(({ data }) => {
        this.machines = data;
      });

    },
    changedWork() {
      this.readyToUseTemplate();
      this.checkDiaryCreation();
      WorkUnitList.get(this.fields.work_id).then((resp) => {
        this.budgeted_units = resp.data
      })

      this.checkWorkFronts()
    },
    checkWorkFronts() {
      Work.find(this.fields.work_id).then(({ data }) => {
        if (data.work_fronts.length > 0) {
          this.enableWorkFronts = true
          this.work_fronts = data.work_fronts

          if (this.work_fronts.length == 1)
            this.fields.work_front_id = this.work_fronts[0].id
        }
      });
    },
    changedMachineEndHour(machine) {
      if (machine.started_hours > machine.finished_hours) {
        machine.finished_hours = ''
        this.error.message = '[' + machine.code + '] - ' + machine.name + "\n\nAs Horas/KM Fim têm de ser superiores às Horas/KM Inicio\n\nO valor terá de ser igual ou superior a " + machine.started_hours
        this.error.showExtensive = true
        return true
      }
      return false
    },
    shakeWorkHandButtonsFunction() {
      this.shakeWorkHandButtons = true
      setTimeout(() => {
        this.shakeWorkHandButtons = false
      }, 3500)
    },
    shakeMachineButtonsFunction() {
      this.shakeMachineButtons = true
      setTimeout(() => {
        this.shakeMachineButtons = false
      }, 3500)
    },
    // Função para compor os números de linha a NULL
    // Vão ficar exatamente como estão no ecrã
    fixWorkUnitLineNumber() {
      let line_number = 0
      this.fields.work_units.forEach(element => {
        element.line_number = line_number
        line_number++
      });
    },
    exportDiary(type) {
      let data = {
        //type: 'PDF',
        type: type,
        id: this.$route.params.id
      }
      Diary.export(data)
        .then((resp) => {
          let caminho = data.type == 'PDF' ? resp.data.object.url : resp.data.object
          let remover = data.type == 'PDF' ? resp.data.object.path : resp.data.object
          window.open(caminho, '_blank')
          Export.delete(remover)
            .then(() => {

            });
        });
    },
    async downloadImages() {
      let i = this.$route.params.id
      await Downloads.get(i)
        .then((response) => {
          window.open(response.data, '_blank');
        });

      await Downloads.delete(i.id)
        .then(() => {

        });
    },
    changeModalTextArea(t) {
      this.inputEditorModalData.object.reason = t
      this.keyReason++
    },
    openEditorModal(item, fields, place) {
      let editable = false

      console.log(fields)
      if(place == 'mao_obra'){
        if(fields.approvals != undefined){
            console.log(fields.approvals)
          if (typeof fields.approvals.topics.mao_obra === 'string'){
            editable = fields.approvals.topics.mao_obra
          }else{
            editable = !fields.approvals.topics.mao_obra
          }
        }
      }

      if(place == 'unidades_obra'){
        if(fields.approvals != undefined){
          if (typeof fields.approvals.topics.unidades_obra === 'string'){
            editable = fields.approvals.topics.unidades_obra
          }else{
            editable = !fields.approvals.topics.unidades_obra
          }
        }
      }

      this.inputEditorModalData.itemModel = item.reason
      this.inputEditorModalData.id = item.id
      this.inputEditorModalData.object = item
      this.inputEditorModalData.editable=editable
      this.editorModal = true
    },
    async defineTemplate() {
      this.useTemplate = !this.useTemplate
      let parent = this
      let workers_template = null

      if (this.useTemplate == false) {
        this.fields.workers = []
        this.fields.machines = []
        this.fields.work_units = []
        this.fields.newWorkUnit = null
        return;
      }

      let params = {
        work_id: this.fields.work_id,
        work_Front_id: this.fields.work_front_id
      }

      await Work.importTemplate(params)
        .then(function ({ data }) {

          parent.templateField = data

        });


      // Template Mao Obra
      this.templateField.workers.forEach(a1 => {
        let findObject = null
        parent.workers.forEach(a2 => {
          if (a1.worker_id == a2.id)
            findObject = a2
        });

        // Verificar se já existe
        let exists = false
        parent.fields.workers.forEach(field_worker => {
          if(field_worker.id == a1.worker_id)
            exists = true
        });

        if(exists == false){
          parent.fields.workers.push({
            id: a1.worker_id,
            name: a1.worker_object.name,
            started_hour: a1.started_hour,
            started_minute: a1.started_minute,
            finished_hour: a1.finished_hour,
            finished_minute: a1.finished_minute,
            slept_night: a1.slept_night,
            night_work: a1.night_work,
            lunch: a1.lunch,
            breakfast: a1.breakfast,
            worked_hours: a1.worked_hours,
            cost: a1.worker_object.value * a1.worked_hours,
            object: findObject
          });
        }
      });

      // Template Maquina
      this.templateField.machines.forEach(a1 => {
        let findObject = null
        let average = 0
        parent.machines.forEach(a2 => {
          if (a1.machine_id == a2.id) {
            findObject = a2
            average = a2.average_km
          }
        });
        // maquinas ficam com a última hora
        parent.fields.machines.push({
          id: a1.machine_id,
          name: a1.name,
          code: a1.code,
          diesel: '',
          ad_blue: null,
          started_hours: findObject == null ? 0 : findObject.next_start,
          finished_hours: a1.finished_hours,
          stopped_reason: a1.stopped_reason,
          worked_hours: a1.worked_hours,
          object: findObject,
          average_km: average
        });
      });

      // Template Unidades
      let line_number_work_unit = 0
      this.templateField.units.forEach(a1 => {
        let findObject = null
        parent.units.forEach(a2 => {
          if (a1.unit_id == a2.id)
            findObject = a2
        });

        parent.fields.work_units.push({
          id: findObject.id,
          name: findObject.name,
          code: findObject.code,
          measure: a1.measure,
          unit: findObject.unit,
          unit_id: findObject.id,
          line_number: line_number_work_unit
        });
        line_number_work_unit++
      });



      this.fields.newWorkUnit = workers_template
    },
    readyToUseTemplate() {
      if (this.fields.work_id == null)
        return false

      let parent = this
      let returner = false

      this.works.forEach(element => {
        if (element.id == parent.fields.work_id)
          if (element.template != null) {
            //if(element.template.machines.length > 0 || element.template.units.length > 0 || element.template.workers.length > 0){
            returner = true
            parent.templateField = element.template
            //}
          }
      });

      // CHECK IF THERE IS OTHER DIARY IN THIS DAY FOR THIS WORK
      //if(this.fields.date != null){
      //this.checkDiaryCreation()
      //}


      return returner
    },
    async checkDiaryCreation() {
      if (this.fields.date == null)
        return null;
      if (this.fields.work_id == null)
        return null;

      // Verificar se é preciso frente
      if(this.work_fronts.length > 0){
        if(this.fields.work_front_id == null){
          return null
        }
      }

      this.loading = true
      let data = {
        data: this.fields.date,
        work: this.fields.work_id,
        work_front_id: this.fields.work_front_id
      }

      let parent = this
      if (navigator.onLine) {
        await Diary.checkWorkCreationValidation(data)
          .then(function (resp) {
            if (resp.data.diary != null) {
              parent.fields.date = null
              parent.error.message = 'Já existe uma Diária criada para este dia, para a mesma obra'
              parent.error.showExtensive = true
              parent.diaryCreationPossible = false
            } else {
              parent.error.showExtensive = false
              parent.diaryCreationPossible = true
              parent.QRCodehasData()
            }
            parent.loading = false
          });
      }
      else {
        parent.error.showExtensive = false
        parent.diaryCreationPossible = true
        parent.QRCodehasData()
        parent.loading = false
      }
      this.success = false
    },
    resetFields() {
      this.fields = {
        id: null,
        work_id: null,
        code: null,
        date: null,
        workers: [],
        work_units: [],
        machines: [],
        name: null,
        status: null,
        description: null,
        started_at: null,
        finished_at: null,
        commissioner_id: null,
        overseer_id: null,
        engineer_id: null,
        files: null,
        newWorkUnit: null,
        machinesWorkUnit: null
      }
    },
    replicarPD() {
      this.replicar = !this.replicar

      let parent = this
      if (this.replicar == false) {
        let work_id = this.fields.work_id;
        let work_front = this.fields.work_front_id
        //let code = this.fields.code
        let date = this.fields.date
        this.resetFields();
        this.fields.work_id = work_id
        this.setDiaryCode(work_id)
        //this.fields.code = code
        this.fields.date = date
        this.fields.work_front_id = work_front
        return
      }

      let data_o = {
        work_id: this.fields.work_id,
        work_front_id: this.fields.work_front_id
      }

      Diary.getLast(data_o)
        .then(function ({ data }) {
          if (data == '') {
            parent.resetFields()
            parent.error.message = "Não há registos antigos para esta Obra"
            parent.error.show = true
            parent.replicar = false
            return
          }



          parent.fields.work_id = data.work_id


          data.workers.forEach(element => {
            parent.fields.workers.push({
              id: element.id,
              name: element.name,
              started_hour: element.started_hour,
              started_minute: element.started_minute,
              finished_hour: element.finished_hour,
              finished_minute: element.finished_minute,
              slept_night: false,
              night_work: false,
              lunch: element.lunch,
              breakfast: false,
              worked_hours: [],
              object: element
            });
          });

          data.machines.forEach(element => {
            // Obter últimas horas para cada máquina
            parent.fields.machines.push({
              id: element.id,
              name: element.name,
              code: element.code,
              diesel: '',
              ad_blue: null,
              uses_ad_blue: element.uses_ad_blue,
              uses_diesel: element.uses_diesel,
              started_hours: element.finished_hours,
              finished_hours: 0,
              stopped_reason: "",
              worked_hours: [],
              object: element,
              average_km: element.average_km,
            });
          });

          let line_number_work_unit = 0
          data.work_units.forEach(element => {
            parent.fields.work_units.push({
              id: element.id,
              name: element.name,
              code: element.code,
              measure: 0,
              unit: element.unit,
              object: element,
              unit_id: element.unit_id,
              reason: element.reason,
              line_number: line_number_work_unit
            });
            line_number_work_unit++
          });
        })

    },
    closeWithWork(mo) {
      this.fields.newWorkUnit = mo
      this.reloadUnidadeObra++

      //this.fillBaseData()
      //this.generalKey++
    },
    closeWithMachineWork(m) {
      this.fields.machinesWorkUnit = m
      this.reloadUnidadeObra++
    },
    replaceComma(place) {
      place.measure = place.measure.replace(',', '.')
      this.reloadUnidadeObra++
    },
    async setDiaryCode(id) {
      if (this.fields.id != null) {
        return;
      }


      let self = this;

      await Work.find(id)
        .then(async function ({ data }) {



          let isValid = false
          let i = 1;

          do{
            let date = dayjs().toDate();

            let incrementor = data.n_diaries + i

            if (incrementor < 10)
              incrementor = '00' + incrementor
            else if (incrementor < 100)
              incrementor = '0' + incrementor
            else incrementor = '' + incrementor

            self.fields.code = data.code + date.getFullYear() + incrementor

            // validar se código é possível
            await Diary.checkValidCode(id, self.fields.code).then((resp)  =>  {
              isValid = resp.data
            })
            i++
          }while(isValid == false);

          self.blockReplicar = false
        });
    },
    fillRestMachines(machineId) {

      let workUnitIndex = this.getWorkUnitIndex(this.work_unit_id);

      let machineIndex = this.getMachineIndex(machineId);

      let machine = this.fields.machines[machineIndex];

      let workedHours = machine.worked_hours[workUnitIndex];

      for (var i = machineIndex; i < this.fields.machines.length; i++) {
        this.fields.machines[i].worked_hours[workUnitIndex] = workedHours;
      }

      this.renderMachineWorkedHours++;
    },
    fillRestWorkers(workerId) {

      let workUnitIndex = this.getWorkUnitIndex(this.work_unit_id);

      let workerIndex = this.getWorkerIndex(workerId);

      let worker = this.fields.workers[workerIndex];

      let workedHours = worker.worked_hours[workUnitIndex];

      for (var i = workerIndex; i < this.fields.workers.length; i++) {
        this.fields.workers[i].worked_hours[workUnitIndex] = workedHours;
      }

      this.renderWorkedHours++;
    },
    addWorkUnit() {
      let size = this.fields.work_units.length
      this.fields.work_units.push({
        id: 'new_' + new Date().getTime(),
        name: "",
        code: "-",
        measure: null,
        unit: "-",
        reason: '',
        line_number: size
      });
    },
    removeWorkUnit(id) {
      let index = this.getWorkUnitIndex(id);

      this.fields.work_units.splice(index, 1);
    },
    addWorker() {
      this.fields.workers.push({
        id: 'new',
        name: "",
        started_hour: 8,
        started_minute: 0,
        finished_hour: 16,
        finished_minute: 0,
        slept_night: false,
        night_work: false,
        breakfast: false,
        lunch: true,
        worked_hours: [],
        reason: '',
        cost: null,
        horas_trabalhadas: null
      });
    },
    removeWorker(id) {
      let index = this.getWorkerIndex(id);

      this.fields.workers.splice(index, 1);
    },
    getWorkerIndex(id) {
      return this.fields.workers.findIndex(item => item.id == id);
    },
    getWorkUnitIndex(id) {
      return this.fields.work_units.findIndex(item => item.id == id);
    },
    getMachineIndex(id) {
      return this.fields.machines.findIndex(item => item.id == id);
    },
    duplicateWorker(worker) {

      let id = dayjs().unix();

      this.fields.workers.push({
        id: id + '-new',
        name: "",
        started_hour: worker.started_hour,
        started_minute: worker.started_minute,
        finished_hour: worker.finished_hour,
        finished_minute: worker.finished_minute,
        slept_night: worker.slept_night,
        night_work: worker.night_work,
        breakfast: worker.breakfast,
        lunch: worker.lunch,
        worked_hours: []
      });
    },
    addMachine() {
      this.fields.machines.push({
        id: 'new',
        name: "",
        code: "-",
        diesel: null,
        ad_blue: null,
        started_hours: null,
        finished_hours: null,
        stopped_reason: "",
        worked_hours: [],
        average_km: 0,
        warning: false,
        cost: null,
        horas_trabalhadas: null
      });
    },
    removeMachine(id) {
      let index = this.getMachineIndex(id);

      this.fields.machines.splice(index, 1);

      // Remover caso exista, das avarias
      let i = 0
      let pos = -1
      this.fields.breakdown_array.forEach(element => {
        if (element.machine_id == id)
          pos = i

        i++
      });

      if (pos != -1) {
        this.fields.breakdown_array.splice(pos, 1)
      }
    },
    duplicateMachine(machine) {
      this.fields.machines.push({
        id: dayjs().unix() + '-new',
        name: "",
        code: "-",
        diesel: machine.diesel,
        ad_blue: null,
        started_hours: machine.started_hours,
        finished_hours: machine.finished_hours,
        stopped_reason: machine.stopped_reason,
        worked_hours: [],
        average_km: machine.average_km
      });
    },
    dividirHorasTodos() {
      // maquinas
      // maodeobra
      if (this.divideHours.type == 'maodeobra') {
        this.fields.work_units.forEach(element => {
          this.showWorkers(element.id, true)
        })
      } else {
        this.fields.work_units.forEach(element => {
          this.showMachines(element.id, true)
        });
      }


      this.successDivided = true
    },
    showWorkers(id, auto = false) {
      this.work_unit_id = id;
      this.workers_auto = auto
      if (auto)
        this.showAutoWorkers = false
      else this.showAutoWorkers = true

      this.showingWorkers = true;

      this.divideHours.show = false
    },
    showMachines(id, auto = false) {
      this.work_unit_id = id;
      this.machines_auto = auto
      if (auto)
        this.showAutoMachines = false
      else this.showAutoMachines = true

      this.showingMachines = true;

      this.divideHours.show = false
    },
    async uploadImageSuccess(formData) {
      if (this.fields.id == null) {
        this.uploadImages.push(formData)
        return
      }

      await Diary.createImage(formData, this.fields.id).then(({ data }) => {
        this.images.push(data)
      }).catch(err => {

        if (err.response.status == 422) {

          this.error.title = "Erro " + err.response.status;

          this.error.message = err.response.data.errors.file[0];


          this.error.show = true;
          return;
        }

      });
    },
    beforeRemove(index, done, fileList) {
      if (this.locked_edition) {
        return;
      }

      this.openConfirmationRemoveImage = true
      this.fileIndex = index
      this.fileList = fileList
    },
    submitRemoveImage() {
      if (this.fields.id == null) {
        this.uploadImages.splice(this.fileIndex, 1)
        this.fileList.splice(this.fileIndex, 1)
        this.openConfirmationRemoveImage = false
        return
      }

      this.openConfirmationRemoveImage = false

      let image = this.images[this.fileIndex].id;

      Diary.deleteImage(image, this.fields.id).then(() => {

        this.images.splice(this.fileIndex, 1);

      }).catch(err => {

        this.error.title = "Erro " + err.response.status;

        this.error.message = err.response.data.message;

      });
    },
    onSuccess() {
      if (this.fields.id != null) {
        this.success = false
        if (this.refreshComponent)
          this.$emit('reloadAll')
        return
      }


      this.$router.push('/admin/diaries');
    },
    buildImages(data) {
      this.images = []
      if(data.files != null)
        if (data.files.length) {
          data.files.forEach(file => {
            this.images.push({
              id: file.id,
              path: file.path,
              default: 0,
              highlight: 0,
            })
          })
        }
    },
    reload() {
      this.reloadUnidadeObra++
      this.$emit('reload', this.fields)

    },
    fillBaseData() {
      if(navigator.onLine){
        Work.listStatus().then(({ data }) => {
          this.status = data;

          this.fields.status = this.fields.status == null ? data.WAITING : this.fields.status;
        });
      }

      Unit.list().then(({ data }) => {
        this.units = data;
      });

      Worker.list().then(({ data }) => {
        this.workers = data;
      });

      Work.list({ status: "STARTED" }).then(({ data }) => {
        this.works = data;

        if (this.works.length == 1) {

          this.fields.work_id = this.works[0].id;
          this.checkWorkFronts()

          this.setDiaryCode(this.works[0].id)
        }
      });

      System.getSystemConfiguration().then((resp)  =>  {
        this.adblueAveragePrice = resp.data.adblue_cost
      })

      Machine.getDieselPrice().then((resp)  =>  {
        this.dieselAveragePrice = resp.data
      })

      this.fields.date = this.$route.query.date != undefined ? this.$route.query.date : this.fields.date;

      // Só para ajudar a saber se estamos a editar ou a criar uma nova
      if (this.fields.id == null)
        this.newDiary = true
      else
        this.newDiary = false

        
    },
    formatDate() {

      let date = dayjs();

      if (this.fields.date != null) {
        date = dayjs(this.fields.date);
      }

      return date.format('DD/MM/YYYY');
    },
    async approve() {
      let fieldsValid = this.validateSubmissionFields()

      if (fieldsValid == false) {
        this.loading = false;
        return
      }


      this.fields.toApproveFlag = true;
      await this.submit(false);

      setTimeout(() => {
        this.checkApproval = false
        this.successApproved = true
      },
      1000);
    },
    async passDiesel() {
      this.dieselObject.ignoreDiesel = true
      this.dieselObject.emptyDiesel = false

      if (this.isToApprove) {
        this.isToApprove = false
        await this.submit(false)
        await this.approve()
      } else this.postChanges()
    },
    areHoursValid() {
      let flag = true;

      this.error.message = 'A divisão de horas nas unidades de Obra é superior ao trabalhado, para os seguintes colaboradores:'
      if (this.fields.work_units.length > 0) {
        this.fields.workers.forEach(element => {
          let dt1 = new Date("October 13, 2023 " + element.started_hour + ":" + element.started_minute + ":00");
          let dt2 = new Date("October 13, 2023 " + element.finished_hour + ":" + element.finished_minute + ":00");

          var diff = (dt2.getTime() - dt1.getTime()) / 1000;
          diff /= (60 * 60);
          diff = Math.abs(Math.round(diff));

          if (element.lunch) {
            if (element.code == 25)
              diff = diff - 1.5
            else
              diff = diff - 1
          }


          let workerSumHours = 0
          if (this.fields.worker_work_units != undefined) {
            this.fields.worker_work_units.forEach(auxiliar => {
              if (auxiliar.worker_id == element.id)
                workerSumHours = parseInt(workerSumHours) + parseInt(auxiliar.worked_hours)
            });
          }

          if (this.fields.newWorkUnit != undefined || this.fields.newWorkUnit != null)
            this.fields.newWorkUnit.forEach(auxiliar => {
              if (auxiliar.worker_id == element.id)
                workerSumHours = parseInt(workerSumHours) + parseInt(auxiliar.worked_hours)
            })

          workerSumHours = Math.round(workerSumHours)


          if (workerSumHours > Math.round(diff)) {
            this.error.message = this.error.message + "<br/><br/>" + element.name;
            flag = false
          }


        });
      }

      if (flag == false)
        this.error.showExtensive = true

      return flag;
    },
    validateFormFields() {
      if (this.fields.workers.length == 0) {
        this.error.message = 'Adicione pelo menos uma mão de obra'
        this.error.show = true
        this.loading = false;
        return false
      }


      if (this.fields.work_units.length > 0) {
        if (this.fields.newWorkUnit == null && this.fields.worker_work_units == null) {
          if (!this.fields.divideAllWorkHand) {
            this.error.message = 'Horas de mão de obra vazias. Por favor preencha os campos em falta\n\n Se pretender o preenchimento automático, selecione o visto da divisão de mão obra'
            this.error.showExtensive = true
            this.loading = false;

            // Abertura do alerta dentro da modal da mão de obra e abertura da modal
            this.shakeWorkHandButtonsFunction()
            return false
          }
        }

        if (this.fields.machines.length > 0) {
          if (this.fields.machinesWorkUnit == null && this.fields.machine_work_units == null) {
            if (!this.fields.divideAllMachines) {
              this.error.message = 'Horas de máquina vazias. Por favor preencha os campos em falta\n\n Se pretender o preenchimento automático, selecione o visto da divisão de máquinas'
              this.error.showExtensive = true
              this.loading = false;

              // Abertura do alerta dentro da modal das maquinas e abertura da modal
              this.shakeMachineButtonsFunction()
              return false
            }
          }
        }
      }

      // Contar quantas unidades de Obra são novas
      let totalNewWorkUnits = 0;
      this.fields.work_units.forEach(element => {
        if (element.id.includes('new'))
          totalNewWorkUnits++
      });


      if (totalNewWorkUnits > 0) {
        if (this.fields.divideAllWorkHand == false) {
          if (this.fields.newWorkUnit == undefined || this.fields.newWorkUnit == null) {
            this.error.message = 'Horas de mão de obra vazias. Por favor preencha os campos em falta\n\n Se pretender o preenchimento automático, selecione o visto da divisão de mão obra'
            this.error.showExtensive = true
            this.loading = false
            return false
          }
        }
      }

      if (totalNewWorkUnits > 0) {
        if (this.fields.machines.length > 0) {
          if (this.fields.divideAllMachines == false) {
            if (this.fields.machinesWorkUnit == undefined || this.fields.machinesWorkUnit == null) {
              this.error.message = 'Horas de máquina vazias. Por favor preencha os campos em falta\n\n Se pretender o preenchimento automático, selecione o visto da divisão de máquinas'
              this.error.showExtensive = true
              this.loading = false
              return false
            }
          }
        }

        let returner = true
        if (this.fields.divideAllWorkHand == false) {
          if (this.fields.newWorkUnit != undefined)
            this.fields.newWorkUnit.forEach(element => {
              if (element.worked_hours == '')
                returner = false
            });
        }

        if (this.fields.divideAllMachines == false) {
          if (this.fields.machinesWorkUnit != undefined)
            this.fields.machinesWorkUnit.forEach(element => {
              if (element.worked_hours == '')
                returner = false
            });
        }

        if (!returner) {
          this.error.message = 'Existem erros nas Unidades de Obra, as horas estão mal distribuidas'
          this.error.showExtensive = true
          this.loading = false
          return false
        }
      }


      let hoursValid = true
      if (this.fields.divideAllWorkHand == false)
        hoursValid = this.areHoursValid();

      if (hoursValid == false) {
        this.loading = false
        return false
      }

      let teste = false
      if (teste) {
        this.loading = false
        return false
      }


      // Check machines fields
      let invalid = false
      let km_hours_error = false
      this.fields.machines.forEach(element => {
        km_hours_error = this.changedMachineEndHour(element)
        if (element.uses_diesel) {
          if (element.diesel == null || element.diesel === "")
            invalid = true
        }
        if (element.started_hours == null)
          invalid = true
        if (element.finished_hours == null)
          invalid = true
      });

      if (km_hours_error) {
        this.loading = false
        return false;
      }
      if (invalid) {
        this.error.message = "Existem campos, nas Máquinas, vazios.\n\nVerifique por favor se os seguintes campos contêm informação:\n\nDiesel\nHoras/KM Inicio\nHoras/KM fim\n\n"
        this.error.showExtensive = true
        this.loading = false

        document.getElementById('machines').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

        return false
      }

      // Check UO fields
      invalid = false
      this.fields.work_units.forEach(element => {
        if (element.measure == null)
          invalid = true
      });

      if (invalid) {
        this.error.message = "Existem campos, nas Máquinas, vazios.\n\nVerifique por favor se os seguintes campos contêm informação:\n\nMedição"
        this.error.showExtensive = true
        this.loading = false

        document.getElementById('workHands').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

        return false
      }



      this.dieselObject = {
        ignoreDiesel: false,
        emptyDiesel: false
      }
    },
    checkEmptyDiesel() {
      // Check machines fields
      let dieselIsEmpty = false
      this.fields.machines.forEach(element => {
        if (element.diesel == '' || element.diesel == 0)
          dieselIsEmpty = true
      });

      if (dieselIsEmpty) {
        if (this.dieselObject.ignoreDiesel == false) {
          this.dieselObject.emptyDiesel = true
          return true
        }
      }
      return false
    },
    async postChanges() {
      // Se for false é porque há um erro
      // então temos de voltar para trás
      let res = await this.validateFormFields()
      if (res == false)
        return;

      // Se for true então é porque existem valores vazios
      // temos de retornar pois a janela a perguntar se tem a certeza que quer avançar foi lançada
      let emptyDiesel = false; //await this.checkEmptyDiesel()
      if (emptyDiesel)
        return

      await this.submit()
    },
    areMachineHoursValid() {
      let flag = true;


      this.error.message = "A divisão de horas nas unidades de Obra é superior ao trabalhado, para as seguintes máquinas:"
      this.fields.machines.forEach(element => {
        let diff = element.finished_hours - element.started_hours

        let machineSumHours = 0
        if (this.fields.machine_work_units != undefined) {
          this.fields.machine_work_units.forEach(auxiliar => {
            if (auxiliar.machine_id == element.id)
              machineSumHours = parseInt(machineSumHours) + parseInt(auxiliar.worked_hours)
          });
        }

        if (machineSumHours > diff) {
          this.error.message = this.error.message + "<br/><br/>" + element.name;
          flag = false
        }


      });

      if (flag == false)
        this.error.showExtensive = true

      return flag;
    },
    async validateSubmissionFields() {
      // **************************************
      // Pelo menos um colaborador é necessário
      // **************************************
      if (this.fields.workers.length == 0) {
        this.error.message = "Adicione pelo menos uma mão de obra"
        this.error.show = true
        this.loading = false;
        return false
      }

      // Validar horários dos colaboradores para o dia em questão


      // Validar horário do colaborador
      let params = {
        date: this.fields.date,
        workers: this.fields.workers,
        diary_id: this.$route.params.id
      }

      let stop = false
      if (navigator.onLine) {
        await Worker.validateHours(params).then((resp) => {
          let data = resp.data

          // Houve algum problema?
          // Se sim, mostrar o mesmo
          if (data.success == false) {
            this.error.message = data.problems.toString().replace(',', '\n\n')
            this.error.showExtensive = true
            stop = true
          }
        });
      }

      if (stop) {
        this.loading = false;
        return false;
      }





      // Se existirem unidades de obra, então:

      // Então tem de existir horas divididas nas unidades de obra, dos colaboradores
      if (this.fields.work_units.length > 0) {
        if (this.fields.worker_work_units == null) {
          if (!this.fields.divideAllWorkHand) {
            this.error.message = "Horas de mão de obra vazias. Por favor preencha os campos em falta\n\n Se pretender o preenchimento automático, selecione o visto da divisão de mão obra"
            this.error.showExtensive = true
            this.loading = false;

            // Abertura do alerta dentro da modal da mão de obra e abertura da modal
            this.shakeWorkHandButtonsFunction()
            return false
          }
        }

        // Se existirem máquinas
        // Então tem de existir horas divididas nas unidades de obra, das máquinas
        if (this.fields.machines.length > 0) {
          if (this.fields.machine_work_units == null) {
            if (!this.fields.divideAllMachines) {
              this.error.message = "Horas de máquina vazias. Por favor preencha os campos em falta\n\n Se pretender o preenchimento automático, selecione o visto da divisão de máquinas"
              this.error.showExtensive = true
              this.loading = false;

              // Abertura do alerta dentro da modal das maquinas e abertura da modal
              this.shakeMachineButtonsFunction()
              return false
            }
          }
        }




      }



      // Validar se todas as horas das unidades de obra são válidas
      // Ou seja, se alguma ultrapassar as horas trabalhadas então terá de haver um erro
      let hoursValid = true
      if (this.fields.divideAllWorkHand == false)
        hoursValid = this.areHoursValid();

      if (hoursValid == false) {
        this.loading = false
        return false
      }

      // Verificar se o somatório das horas das máquinas nas UO não ultrapassa o máximo
      if (this.fields.divideAllMachines == false) {
        let machinesValid = this.areMachineHoursValid()
        if (machinesValid == false) {
          this.loading = false
          return false
        }
      }



      let allValid = true
      this.fields.work_units.forEach(element => {
        if (this.validWorkUnit(element).result == false)
          allValid = false
      })

      if (allValid == false) {
        this.error.message = "Existem problemas nas unidades de obra. Por favor verifique as mesmas"
        this.error.show = true;
        this.loading = false
        return false
      }

      return true;
    },
    async uploadImagesAfterCreate(data) {
      this.fields.id = data.id

      this.uploadImages.forEach(async element => {
        await this.uploadImageSuccess(element)
      });

      this.fields.id = null
    },
    async submit(showSuccess = true) {
      this.$refs.form.validate().then(async (result) => {
        this.loading = true;

        if (!result) {
          this.loading = false;
          return;
        }

        let fieldsValid = await this.validateSubmissionFields()

        if (fieldsValid == false) {
          this.loading = false;
          return
        }

        this.fields.user_creator = this.$root.session.id;


        // EXISTE CONEXAO HÁ INTERNET?
        // Se sim, enviar para o servidor
        // Senão guardar localmente em cache
        if (navigator.onLine) {
          await Diary[this.method](this.fields, this.fields.id).then(async ({ data }) => {

            if (this.method == 'update')
              this.refreshComponent = true

            // Se tiver imagens para inserir, devemos inserir as imagens
            await this.uploadImagesAfterCreate(data)

          }).catch(err => {
            this.loading = false;

            showSuccess = false

            if (err.response.status == 422) {
              this.error.title = "[Erro]"

              this.error.message = err.response.data.errors.code[0]

              this.error.show = true

              this.$refs.form.setErrors(err.response.data.errors);


              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

            this.error.show = true

            return;

          });

          if (showSuccess) {
            this.success = true;
            this.loading = false;
          }
        }
        else {
          try {
            this.work_fronts.forEach(element => {
              if(element.id == this.fields.work_front_id)
                this.fields.work_front_name = element.reference
            });

            console.log('fields', this.fields);
            await saveDiaryOffline(this.fields);
            console.log('Diary saved successfully');
          } catch (error) {
            console.error('Failed to save diary offline:', error);
          }
          if (showSuccess) {
            this.success = true;
            this.loading = false;
          }
        }
      });
    },
    fillUnit(item) {
      this.action.unit = item;
    },
    filterAutocomplete(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    submitWorker() {
      this.showingWorkers = false;
    },
    submitMachine() {
      this.showingMachines = false;
    },
  },
  computed:{
    calculateTotalWorkersCost(){
      let total = 0

      this.fields.workers.forEach(element => {
        total = total + parseFloat(element.cost)
      });
      return total.toFixed(2) + '€'
    },
    calculateTotalMachinesCost(){
      let total = 0

      this.fields.machines.forEach(element => {
        total = total + parseFloat(element.cost)
      });
      return total.toFixed(2) + '€'
    }
  }
};
</script>
<style scoped>
::v-deep .v-item-group {
  padding-right: 1rem;
}

::v-deep .image-container {
  width: 160px;
  height: 140px;
}

::v-deep .image-list-container.display-flex.flex-wrap {
  max-width: 100%;
  margin-bottom: 40px;
}

::v-deep .image-list-container .image-list-item {
  width: 100px;
  height: 100px;
}

::v-deep .image-list-container .image-list-item .show-img {
  max-width: 90px;
  max-height: 90px;
}

::v-deep .image-icon-delete {
  position: absolute;
  width: 3rem;
  height: 1.5rem;
  fill: red;
  margin-top: -7.5rem;
  margin-left: 1rem;
}

::v-deep .image-icon-edit {
  display: none;
  width: 2rem;
  height: 3rem;
  fill: #673ab7;
  margin-left: 2.5rem;
  margin-top: -1.3rem;
}

::v-deep .add-image-svg {
  width: 17px;
  height: 17px;
  fill: white
}

::v-deep .image-list-item.position-relative.cursor-pointer.display-flex.justify-content-center.align-items-center {
  background: var(--v-primary-base);
  border-radius: 34px;
  width: 3.7rem;
  height: 3.7rem;
  top: 1rem;
  left: 1rem;
  border: none;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

::v-deep .v-item-group {
  padding-right: 0;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.custo_total {
  margin-left: 2rem;
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
}
</style>
