<template>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
         <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row>
            <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" vid="Código" name="Código" rules="required">
                <v-autocomplete
                  dense outlined
                  v-model="fields.work_id"
                  :items="works"
                  item-value="id"
                  item-text="code"
                  readonly
                  prepend-inner-icon="mdi-account-details"
                  label="Código *"
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="Obra" name="Obra" rules="required">
                <v-autocomplete
                  dense outlined
                  v-model="fields.work_id"
                  :items="works"
                  item-value="id"
                  item-text="name"
                  readonly
                  prepend-inner-icon="mdi-account-details"
                  label="Obra *"
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" vid="Data" name="Data" rules="required">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fields.data"
                      label="Data *"
                      readonly
                      dense
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fields.data"
                    no-title
                    scrollable
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </validation-provider>
            </v-col>
            <v-col cols="12" v-if="fields.data != null">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Artigo
                                </th>
                                <th class="text-left">
                                    Quant.
                                </th>
                                <th class="text-left">
                                    Preço Unit. (€)
                                </th>
                                <th class="text-left">
                                    Total (€)
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="artigo in fields.lines" :key="artigo.id">
                            <tr>
                              <td style="width: 65%;" class="mx-4 px-4">
                                <validation-provider v-slot="{ errors }" vid="Artigo" name="Artigo" rules="required">
                                    <v-autocomplete
                                        v-model="artigo.article_id"
                                        :items="filtrarArtigos"
                                        label="Artigo *"
                                        :error-messages="errors"
                                        dense outlined class="mt-4"
                                        :item-text="item => item.code + ' - ' + item.description"
                                        item-value="id"
                                    />
                                </validation-provider>
                              </td>
                              <td style="width: 15%;">
                                <v-text-field
                                  v-model="artigo.qty"
                                  :type="'number'"
                                  dense
                                  label="Quantidade"
                                  outlined
                                  class="mt-4"
                                  placeholder="0"
                                  @input="updateLine(artigo)"
                                  persistent-hint
                                  :hint="'Qtd. Acumulada: ' + getQuantity(artigo)"
                                />
                              </td>
                              <td style="width: 10%;">
                                {{ $currency(getUnitPrice(artigo)) }}
                              </td>
                              <td style="width: 10%;">
                                {{ $currency(artigo.total_price) }}
                              </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12" align="center" v-if="fields.data != null">
                <v-divider></v-divider>
                <v-btn :disabled="invalid" fab class="success mt-4" @click="addLine">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" align="center" v-if="fields.data != null">
                <v-btn @click="submit" :disabled="invalid" class="success">
                    Gravar
                </v-btn>
            </v-col>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
        Auto de Medição gravado com sucesso!
      </DialogSuccess>
    </validation-observer>
  </template>

  <script>
  import WorkMeasurements from "@/api/WorkMeasurements.js";
  import Work from '@/api/Work.js'
  import Article from '@/api/Article.js'
  import DiaryArticles from '@/api/DiaryArticles.js'
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import dayjs from 'dayjs'

  export default {
    components: {
      DialogSuccess
    },
    props: {
     measure: Object,
     method: String,
     disabled: Boolean,
   },
   watch: { 
    measure: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }

          let data = {...val};

          this.fields = data;

          this.fields.id = data.id;
        }
      },
      'fields.data':{
        immediate: true,
        handler(val){
            if(val == null) return

            this.getDiariesData()
        }
      }
    },
    data: () => ({
      fields: {
        id: null,
        work_id: null, 
        data: null,
        total: null,
        lines: []
      },
      articles:[],
      works: [],
      diaries_data: [],
      filteredArticles: [],
      loading:false,
      menu: false,
      error: {
        title: '',
        message: '',
      },
      success: false,
    }),
    mounted(){
        if(this.method == 'create')
            this.fields.work_id = this.$route.params.work_id

        this.fillBaseData();
    },
    methods:{
      updateLine(artigo) {
        // Atualiza o preço total ou outros valores dependentes
        const index = this.fields.lines.findIndex(line => line.id === artigo.id);
        if (index !== -1) {
          this.fields.lines[index].total_price = this.getUnitPrice(artigo) * artigo.qty;
        }
      },
      getQuantity(item){
        let quantity = 0
        
        this.filteredArticles.forEach(element => {
          if(element.id == item.article_id){
            quantity = element.qty
          }
        });
        return quantity
      },
      getUnitPrice(item){
        let price = 0

        this.filteredArticles.forEach(element => {
          if(element.id == item.article_id){
            price = element.unit_price
            item.unit_price = element.unit_price
          }
        });
        return price
      },
      getDiariesData(){
          if(this.fields.data == null) return 

          let params = {
              data: this.fields.data,
              work_id: this.fields.work_id
          }

          DiaryArticles.getDiariesData(params).then((resp) =>  {
            this.diaries_data = resp.data
          })
      },
      addLine(){
          this.fields.lines.push({
              id: dayjs().unix() + '-new',
              article_id: null,
              qty: 0, 
              unit_price: null,
              obs: null
          })
      },
      onSuccess() {
          if(this.fields.id != null) {
            this.success = false
            this.$router.push('/admin/works-settings/work-measurements/update/' + this.fields.id)
            return
          }
          this.$router.push('/admin/works-settings/work-measurements/update/' + this.fields.id)
          this.success = false
      },
      fillBaseData(){
          Work.list().then((resp) =>  {
              this.works = resp.data
          })

          Article.list().then((resp)  =>  {
              this.articles = resp.data
          })

          this.getDiariesData();
      },
      submit() {
          this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
              this.loading = false;
              return;
          }

          let data = {...this.fields};

          WorkMeasurements[this.method](data, this.fields.id).then((resp) => {

              this.success = true;

              this.fields.id = resp.data.id

              this.loading = false;

          }).catch(err => {
              this.loading = false;

              if(err.response.status == 422) {

                  this.$refs.form.setErrors(err.response.data.errors);
                  
                  return;
              }

              this.error.title = "Erro " + err.response.status;

              this.error.message = err.response.data.message;

              });
          })
      },
      setFilteredArticles(a){
        this.filteredArticles = a
      }
    },
    computed:{
      filtrarArtigos(){
        let filtro = []
        this.diaries_data.forEach(artigo_diaria => {
          this.articles.forEach(element => {
            if(element.id == artigo_diaria.article_id){
              element.qty = parseFloat(artigo_diaria.total_qty)
              filtro.push(element)
            }
          });
        });
        this.setFilteredArticles(filtro)
        return filtro
      }
    }
  };
  </script>
