<template>
    <section > 
      
      
        <div style="position:relative">
          <v-row justify="start">
            <v-col cols="12" md="6" class="text-caption font-weight-bold" v-if="$root.session.hasRole(['super', 'encarregado'])">
              Opções encarregado:
              <v-btn @click="registerExit" class="error" small><v-icon class="mr-2" small>mdi-exit-run</v-icon>Registar saída</v-btn>
            </v-col>
            <v-col cols="12" md="6" align="end">
              <v-btn small @click="searchSubscribed" class="success"><v-icon class="mr-2" small>mdi-refresh</v-icon>Atualizar lista</v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            locale="pt-pt"
          >
          <template v-slot:item.entrance="{item}">
            {{item.hora_entrada}}:{{item.minuto_entrada}}
          </template>
          <template v-slot:item.exit="{item}">
            {{getSaida(item)}}   
          </template>
  
            
          </v-data-table>
          
        </div>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Hora de saída gravada com sucesso!
        </DialogSuccess>
    </section>
  </template>
  <script>
  import QRCode from "@/api/QRCode.js";
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  
  export default {
    props:{
      work_id: String,
      front_id: {
        typeof: String,
        default: null
      }
    },
    components: {
      DialogSuccess
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchSubscribed()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      success: false,
      headers: [
        {
          text: 'Código',
          sortable: true,
          value: 'worker.code',
        },
        {
          text: 'Colaborador',
          sortable: true,
          value: 'worker.name',
        },
        {
          text: 'Entrada',
          sortable: true,
          value: 'entrance',
        },
        {
          text: 'Saída',
          sortable: true,
          value: 'exit',
        },
        {
          text: 'Criada a',
          sortable: true,
          value: 'created_at',
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
      }
    }),
    mounted(){

      this.fillBaseData()
    },
    methods:{
      getSaida(item){
        if(item.hora_saida == null)
          return '';

        return item.hora_saida + ':' + item.minuto_saida
      },
      registerExit(){
        let data = {
          work_id: this.work_id,
          front_id: this.front_id
        }
        QRCode.registerExit(data).then(() =>  {
          this.success = true
        })
      },
      onSuccess(){
        this.success = false
        this.searchSubscribed()
      },
      fillBaseData() {
        if(localStorage["qr-code-subscribed-v2-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["qr-code-subscribed-v2-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchSubscribed(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
        
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          work_id: this.work_id,
          front_id: this.front_id
        };
      
        Object.assign(request, filter);
  
        localStorage["qr-code-subscribed-v2-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        QRCode.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;  
          });
  
          this.loading = false;
  
        });
      }
    },
    
  };
  </script>
  